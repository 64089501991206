import React, { useState, useEffect, useCallback } from "react";

// eslint-disable-next-line
/* global TrelloPowerUp */

import SpecialRequirementsContainer from "./SpecialRequirementsContainer";
import CalendarSelector from "./CalendarSelector";
import ClientSelector from "./ClientSelector";
import ConfirmationStatus from "./ConfirmationStatus";
import DueDateExpressContainer from "./DueDateExpressContainer";
import JobNumber from "./JobNumber";
import SpecialEventContainer from "./SpecialEventContainer";
import EventTrackingContainer from "./EventTrackingContainer";
import PrefixSelector from "./PrefixSelector";
import ModelsContainer from "../ModelsContainer";
import HMUContainer from "../HMUContainer";
import MarkdownNotesEditorCalendar from "./MarkdownNotesEditorCalendar";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

import BriefContainer from "./BriefContainer";
import CameraSettings from "./CameraSettings";

// import getJobName from "../../GlobalFunctions/getJobName";

import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RRule } from "rrule";
import NotesContainer from "../../GlobalComponents/NotesContainer";
import MDEditor, { commands } from "@uiw/react-md-editor";

// import Slide from "@mui/material/Slide";
// import Stack from "@mui/material/Stack";
// import Chip from "@mui/material/Chip";

import { debounce } from "lodash";

import { useAtom } from "jotai";
import { isIdleAtom, listsAtom, jobTypesAtom } from "../../-Atoms";

import AdditionalDates from "./Job Tab Components/AdditionalDates";
import AdditionalDatesStaff from "./Job Tab Components/AdditionalDatesStaff";
import StaffContainer from "./Job Tab Components/StaffContainer";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import dayjs from "dayjs";

const { DateTime } = require("luxon");

const JobTab = ({
  firebase,
  jobData,
  clientData,
  studios,
  jobTypes,
  dayTypes,
  handleMenuClose,
  handleMenuClick,
  showLinkBlock,
  setShowLinkBlock,
  event,
  events,
  isAdmin,
  canEdit,
  staffData,
  calendarColours,
  confirmationStatusEmailOpen,
  setConfirmationStatusEmailOpen,
  firstConfirmationStatusEmailOpen,
  setFirstConfirmationStatusEmailOpen,
  setConfirmationEmailData,
  isEventTomorrow,
  setJobNumber,
  jobNumber,
  supplyLink,
  updateFSNow,
  updateFS,
  confirmDeleteJobButtonText,
  confirmDeleteJob,
  confirmDeleteJobButtonShow,
  deleteJob,
  deleteJobButtonShow,
  tracking,
  attachments,
  clientAttachments,
  isMobile,
  specialEvent,
  setSpecialEvent,
  setJobClientData,
  jobClientData,
  currentUser,
  canEditBasic,
  setStopModalClose,
  setAttachments,
  theme,
  newCardID,
}) => {
  const [supplyLinkMenuAnchorElement, setSupplyLinkMenuAnchorElement] =
    useState(null);

  const [assignJobNumberText, setAssignJobNumberText] =
    useState("Assign Job Number");

  const [eventPrefix, setEventPrefix] = useState("");
  const [eventPrefixCustom, setEventPrefixCustom] = useState("");
  const [express, setExpress] = useState(false);
  const [recurringEvent, setRecurringEvent] = useState(false);
  const [rrule, setRrule] = useState({});
  const [exDate, setExDate] = useState([]);

  const [jobType, setJobType] = useState("");
  const [studio, setStudio] = useState("");
  const [client, setClient] = useState("");
  const [title, setTitle] = useState("");
  const [selectedDayType, setSelectedDayType] = useState("");
  const [dayType, setDayType] = useState("");
  const [creativeDayType, setCreativeDayType] = useState("");
  const [imagesScheduledToBeShot, setImagesScheduledToBeShot] = useState("");
  const [confirmationStatus, setConfirmationStatus] = useState("");
  const [photographer, setPhotographer] = useState("");
  const [additionalPhotographers, setAdditionalPhotographers] = useState([]);
  const [operator, setOperator] = useState("");
  const [additionalOperators, setAdditionalOperators] = useState([]);
  const [stylist, setStylist] = useState("");
  const [additionalStylists, setAdditionalStylists] = useState([]);
  const [confirmationEmailSent, setConfirmationEmailSent] = useState(false);
  const [firstConfirmationEmailSent, setFirstConfirmationEmailSent] =
    useState(false);
  const [dueDate, setDueDate] = useState(null);
  const [bookedStart, setBookedStart] = useState(dayjs());
  const [bookedFinish, setBookedFinish] = useState(dayjs());

  // const [noRetouching, setNoRetouching] = useState(false);

  const [additionalDates, setAdditionalDates] = useState([]);

  // const [additionalStart, setAdditionalStart] = useState(new Date());
  // const [additionalFinish, setAdditionalFinish] = useState(new Date());

  const [expressDueDate, setExpressDueDate] = useState(null);
  const [multipleDays, setMultipleDays] = useState("");
  const [comments, setComments] = useState("");
  // const [callTime, setCallTime] = useState(null);
  // const [callTimeUnit, setCallTimeUnit] = useState("");
  const [models, setModels] = useState([]);
  const [hmus, setHmus] = useState([]);
  const [eventNotes, setEventNotes] = useState("");
  const [year, setYear] = useState("");

  const [isPostProductionJob, setIsPostProductionJob] = useState(false);

  const [spreadsheetURL, setSpreadsheetURL] = useState("");

  const [shootSpecialRequirements, setShootSpecialRequirements] = useState("");
  const [invoicingSpecialRequirements, setInvoicingSpecialRequirements] =
    useState("");
  const [retouchingSpecialRequirements, setRetouchingSpecialRequirements] =
    useState("");
  const [invoicingNotes, setInvoicingNotes] = useState(
    jobClientData?.invoicingNotes
  );

  const [lists] = useAtom(listsAtom);
  const [isIdle] = useAtom(isIdleAtom);

  const [idList, setIdList] = useState("");
  useEffect(() => {
    let tmpId = "";
    lists.forEach((list) => {
      if (list.items.indexOf(jobData.id) !== -1) {
        if (!tmpId) {
          tmpId = list.id;
          setIdList(list.id);
        } else {
          alert(
            "List Conflict: Job is in 2 Lists, this shouldn't be possible. Please screenshot this (including the job number) and send it to Alex. The first list to be found is what will be used."
          );
        }
      }
    });

    return () => {
      setIdList("");
    };
  }, [lists, jobData, setIdList]);

  // eslint-disable-next-line
  const [clientSpreadsheetButtonText, setClientSpreadsheetButtonText] =
    useState("Shootlist");

  const [assignJobNumberDisabled, setAssignJobNumberDisabled] = useState(false);
  // console.log(assignJobNumberDisabled);
  useEffect(() => {
    if (
      jobType?.includes("-L") ||
      jobType?.includes("-LC") ||
      jobType?.includes("-PC")
    ) {
      if (dayType || creativeDayType) {
        setAssignJobNumberText("Assign Job Number");
        if (jobNumber) {
          setAssignJobNumberDisabled(true);
        } else {
          setAssignJobNumberDisabled(false);
        }
      } else {
        setAssignJobNumberText("Set Day Type");
        setAssignJobNumberDisabled(true);
      }
    } else {
      if (jobNumber) {
        setAssignJobNumberDisabled(true);
      } else {
        setAssignJobNumberDisabled(false);
      }
    }
  }, [
    jobNumber,
    dayType,
    creativeDayType,
    jobType,
    setAssignJobNumberDisabled,
  ]);

  useEffect(() => {
    setInvoicingNotes(jobClientData?.invoicingNotes);
  }, [jobClientData]);

  // eslint-disable-next-line
  const updateJobName = useCallback(
    debounce(
      (
        jobNumberChange,
        clientChange,
        multipleDaysChange,
        jobTypeFullChange
      ) => {
        try {
          const jts = jobTypeFullChange.match(/^.*\((-.*)\)/)[1];
          const jn = `${jobNumberChange}-${clientChange}${
            multipleDaysChange ? `-${multipleDaysChange}` : ""
          }${jts}`;

          firebase
            .firestore()
            .collection("JobData")
            .doc(jobData.id)
            .set({ jobName: jn || null }, { merge: true });
        } catch (e) {}
      },
      1500
    ),
    // eslint-disable-next-line
    [firebase, jobData]
  );

  useEffect(() => {
    if (jobType === "Retouching (-R)") {
      setIsPostProductionJob(true);
    } else if (jobType === "Video Editing (-E)") {
      setIsPostProductionJob(true);
    } else if (jobType === "Design (-D)") {
      setIsPostProductionJob(true);
    } else {
      setIsPostProductionJob(false);
    }
  }, [jobType]);

  useEffect(() => {
    setSpreadsheetURL(jobClientData.spreadsheetURL);
  }, [jobClientData]);

  // useEffect(() => {
  //   if (!jobData) return;
  //   if (!client) return;
  //   if (!clientData) return;
  //   clientData.forEach((c) => {
  //     if (c.brand === client) setJobClientData(c);
  //   });
  // }, [jobData, client, clientData, setJobClientData]);

  useEffect(() => {
    let data = event?.extendedProps;
    if (jobData.id) data = jobData;
    setStudio(data?.studio);
    setClient(data?.client);
    setJobType(data?.jobTypeFull);
    setEventPrefix(data?.eventPrefix);
    setEventPrefixCustom(data?.eventPrefixCustom);
    // setNoRetouching(data?.noRetouching);

    setMultipleDays(data?.multipleDays);

    setComments(data?.comments);

    setExpress(data?.express);
    if (data?.dayType) {
      setSelectedDayType(`Life - ${data?.dayType}`);
    } else if (data?.creativeDayType) {
      setSelectedDayType(`Content - ${data?.creativeDayType}`);
    } else {
      setSelectedDayType("");
    }
    setDayType(data?.dayType);
    setCreativeDayType(data?.creativeDayType);
    setImagesScheduledToBeShot(data?.imagesScheduledToBeShot);
    setConfirmationStatus(data?.confirmationStatus);
    setPhotographer(data?.photographer);
    setAdditionalPhotographers(data?.additionalPhotographers || []);
    setOperator(data?.operator);
    setAdditionalOperators(data?.additionalOperators || []);
    setStylist(data?.stylist);
    setAdditionalStylists(data?.additionalStylists || []);

    // setCallTime(data?.callTime);
    // setCallTimeUnit(data?.callTimeUnit);

    setYear(data?.year);

    setConfirmationEmailSent(data?.confirmationEmailSent);

    setFirstConfirmationEmailSent(data?.firstConfirmationEmailSent);

    setRecurringEvent(data?.recurringEvent);
    setRrule(data?.rrule);
    if (data?.exdate) {
      setExDate(data?.exdate);
    } else {
      if (event?.extendedProps?.id) {
        updateFSNow(firebase, event.extendedProps.id, "exdate", []);
      }
    }

    setModels(data?.models);
    setHmus(data?.hmu);

    if (data?.eventNotes !== eventNotes) {
      setEventNotes(data?.eventNotes);
    }
    if (data?.shootSpecialRequirements !== shootSpecialRequirements) {
      setShootSpecialRequirements(data?.shootSpecialRequirements);
    }
    if (data?.invoicingSpecialRequirements !== invoicingSpecialRequirements) {
      setInvoicingSpecialRequirements(data?.invoicingSpecialRequirements);
    }
    if (data?.retouchingSpecialRequirements !== retouchingSpecialRequirements) {
      setRetouchingSpecialRequirements(data?.retouchingSpecialRequirements);
    }
    try {
      setBookedStart(dayjs(data?.bookedStart.toDate()));
    } catch (e) {
      setBookedStart(dayjs(data?.bookedStart));
    }
    try {
      setBookedFinish(dayjs(data?.bookedFinish.toDate()));
    } catch (e) {
      setBookedFinish(dayjs(data?.bookedFinish));
    }

    setAdditionalDates(data?.additionalDates || []);

    // try {
    //   setAdditionalStart(data?.additionalStart.toDate());
    // } catch (e) {
    //   setAdditionalStart(data?.additionalStart);
    // }
    // try s
    //   setAdditionalFinish(data?.additionalFinish.toDate());
    // } catch (e) {
    //   setAdditionalFinish(data?.additionalFinish);
    // }

    try {
      setDueDate(data?.dueDate.toDate());
    } catch (e) {
      setDueDate(data?.dueDate);
    }
    try {
      setExpressDueDate(data?.expressDueDate.toDate());
    } catch (e) {
      setExpressDueDate(data?.expressDueDate);
    }

    if (data?.title !== title) {
      setTitle(data?.title);
    }

    // eslint-disable-next-line
  }, [event, jobData]);

  useEffect(() => {
    if (studio === "Staff") {
      setSpecialEvent(true);
    } else if (studio === "N/A") {
      setSpecialEvent(true);
    } else if (studio === "Critical Events") {
      setSpecialEvent(true);
    } else if (studio === "Post Production") {
      setSpecialEvent(true);
    } else if (studio === "Adam") {
      setSpecialEvent(true);
    } else {
      setSpecialEvent(false);
    }
  }, [studio, setSpecialEvent]);

  const handleJobNumberChange = useCallback(
    (e) => {
      if (!canEdit) return;
      let jn = "";
      if (parseInt(e.target.value)) jn = parseInt(e.target.value);
      setJobNumber(jn);

      updateFSNow(firebase, jobData.id, "jobNumber", jn);
      updateJobName(parseInt(e.target.value), client, multipleDays, jobType);
    },

    [
      jobData,
      firebase,
      updateFSNow,
      canEdit,
      updateJobName,
      client,
      jobType,
      multipleDays,
      setJobNumber,
    ]
  );

  // const handleCallTimeChange = useCallback(
  //   (e) => {
  //     if (!canEditBasic) return;
  //     setCallTime(e.target.value);
  //     updateFSNow(firebase, jobData.id, "callTime", e.target.value);
  //   },
  //   [jobData, firebase, updateFSNow, canEditBasic]
  // );

  const handleImagesScheduledChange = (e) => {
    if (!canEdit) return;
    setImagesScheduledToBeShot(e.target.value);
    updateFSNow(
      firebase,
      jobData.id,
      "imagesScheduledToBeShot",
      e.target.value
    );
  };

  const handleYearChange = (e) => {
    if (!canEdit) return;
    setYear(parseInt(e.target.value));
    updateFSNow(firebase, jobData.id, "year", parseInt(e.target.value));
  };

  const sendNotificationToUID = firebase
    .functions()
    .httpsCallable("sendNotificationToUID");

  const handleSupplyLinkMenuClick = (event) => {
    setSupplyLinkMenuAnchorElement(event.currentTarget);
  };

  const handleSupplyLinkMenuClose = () => {
    setSupplyLinkMenuAnchorElement(null);
  };

  // const handleCallTimeUnitChange = useCallback(
  //   (e) => {
  //     if (!canEditBasic) return;
  //     console.log(e.target.value);
  //     setCallTimeUnit(e.target.value);
  //     updateFSNow(firebase, jobData.id, "callTimeUnit", e.target.value);
  //   },
  //   [jobData, firebase, updateFSNow, canEditBasic]
  // );

  const handleMultipleDaysChange = useCallback(
    (e) => {
      if (!canEdit) return;
      setMultipleDays(e.target.value);
      updateJobName(jobNumber, client, e.target.value, jobType);
      updateFSNow(firebase, jobData.id, "multipleDays", e.target.value);
    },
    [
      jobData,
      firebase,
      updateFSNow,
      client,
      jobNumber,
      jobType,
      updateJobName,
      canEdit,
    ]
  );

  const handleCommentsChange = useCallback(
    (e) => {
      if (!canEdit) return;
      setComments(e.target.value);
      updateFSNow(firebase, jobData.id, "comments", e.target.value);
    },
    [jobData, firebase, updateFSNow, canEdit]
  );

  const addAdditionalStaff = async (additionalStaff, setAdditionalStaff) => {
    additionalStaff.push("");
    setAdditionalStaff([...additionalStaff]);
  };

  const addAdditionalStaffToAdditionalDate = async (
    index,
    additionalDates,
    additionalStaff
  ) => {
    additionalDates[index][additionalStaff].push("");
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ additionalDates: additionalDates })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClientSpreadsheetButtonClick = () => {
    window.open(spreadsheetURL, "_blank");
  };

  const deleteAttachment = (item, index) => {
    if (!canEdit) return;
    const splice = [...attachments];
    splice.splice(index, 1);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ attachments: splice || "" }, { merge: true })
      .catch((error) => {
        console.log(error);
      });
    setAttachments([...splice]);
  };

  const [currentClient, setCurrentClient] = useState({});

  useEffect(() => {
    if (client) {
      const unsubscribe = firebase
        .firestore()
        .collection("ClientDatabase")
        .where("brand", "==", client)
        .onSnapshot((snapshot) => {
          try {
            const data = snapshot.docs[0].data();
            data.id = snapshot.docs[0].id;
            setCurrentClient({ ...data });
          } catch (e) {
            console.log(e);
          }
        });
      // if (isIdle) {
      //   unsubscribe();
      // }
      return () => {
        unsubscribe();
      };
      // eslint-disable-next-line
    }
  }, [firebase, client, isIdle]);

  useEffect(() => {
    if (bookedStart) {
      const bookedYear = new Date(bookedStart).getFullYear();
      if (jobData) {
        if (year !== bookedYear) {
          setYear(bookedYear);
          updateFSNow(firebase, jobData.id, "year", bookedYear);
        }
      }
    }
    // eslint-disable-next-line
  }, [jobData, year, bookedStart]);

  const handleBookedStartChange = (newValue) => {
    if (!canEdit) return;
    try {
      // newValue is a dayjs object when using AdapterDayjs
      setBookedStart(newValue);

      // Convert to ISO string if needed
      const isoString = newValue.toISOString();

      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({
          bookedStart: isoString,
          start: isoString,
          shootDate: isoString,
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleBookedFinishChange = (newValue) => {
    if (!canEdit) return;
    try {
      setBookedFinish(newValue);
      const isoString = newValue.toISOString();
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update(
          {
            bookedFinish: isoString,
            end: isoString,
          },
          { merge: true }
        )
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setSelectedDayType(value);

    if (value.startsWith("Life")) {
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update(
          {
            dayType: value.replace("Life - ", ""),
            creativeDayType: "",
          },
          { merge: true }
        )
        .catch((error) => {
          console.log(error);
        });
    } else if (value.startsWith("Content")) {
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update(
          {
            dayType: "",
            creativeDayType: value.replace("Content - ", ""),
          },
          { merge: true }
        )
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div style={{ maxWidth: 780 }}>
      <div
        style={{
          position: "absolute",
          top: 62,
          left: isMobile ? 20 : 40,
        }}
      >
        <IconButton onClick={handleMenuClick} style={{ marginRight: 20 }}>
          <MenuIcon />
        </IconButton>
      </div>
      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: spreadsheetURL ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            size="small"
            style={{
              width: 180,
              height: 40,
              fontSize: 12,
            }}
            onClick={handleClientSpreadsheetButtonClick}
          >
            {clientSpreadsheetButtonText}
          </Button>
        </div>

        {supplyLink ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              style={{
                width: 180,
                height: 40,
                fontSize: 12,
                margin: 8,
              }}
              size="small"
              onClick={(e) => {
                handleSupplyLinkMenuClick(e);
              }}
            >
              Supply Link
            </Button>
          </div>
        ) : null}
      </div>
      <CalendarSelector
        isPostProductionJob={isPostProductionJob}
        studios={studios}
        studio={studio}
        setStudio={setStudio}
        jobData={jobData}
        firebase={firebase}
        calendarColours={calendarColours}
        canEdit={canEdit}
      />
      {!specialEvent && !isPostProductionJob ? (
        <>
          <ClientSelector
            clientData={clientData}
            client={client}
            setClient={setClient}
            updateJobName={updateJobName}
            firebase={firebase}
            jobData={jobData}
            jobNumber={jobNumber}
            multipleDays={multipleDays}
            jobType={jobType}
            canEdit={canEdit}
            setDueDate={setDueDate}
          />
          <Menu
            anchorEl={supplyLinkMenuAnchorElement}
            keepMounted
            open={Boolean(supplyLinkMenuAnchorElement)}
            onClose={handleSupplyLinkMenuClose}
            disableScrollLock={true}
            style={{ marginTop: 10 }}
          >
            <MenuItem
              sx={{ width: 200 }}
              onClick={() => {
                navigator.clipboard.writeText(supplyLink.url);
                handleSupplyLinkMenuClose();
              }}
            >
              Copy To Clipboard
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                window.open(supplyLink.url, "_blank");
                handleSupplyLinkMenuClose();
              }}
            >
              Open Link
            </MenuItem>
          </Menu>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            <ConfirmationStatus
              isMobile={isMobile}
              confirmationStatus={confirmationStatus}
              setConfirmationStatus={setConfirmationStatus}
              firebase={firebase}
              jobData={jobData}
              currentClient={currentClient}
              canEdit={canEdit}
              confirmationStatusEmailOpen={confirmationStatusEmailOpen}
              setConfirmationStatusEmailOpen={setConfirmationStatusEmailOpen}
              firstConfirmationStatusEmailOpen={
                firstConfirmationStatusEmailOpen
              }
              setFirstConfirmationStatusEmailOpen={
                setFirstConfirmationStatusEmailOpen
              }
              setConfirmationEmailData={setConfirmationEmailData}
              confirmationEmailSent={confirmationEmailSent}
              setConfirmationEmailSent={setConfirmationEmailSent}
              firstConfirmationEmailSent={firstConfirmationEmailSent}
              setFirstConfirmationEmailSent={setFirstConfirmationEmailSent}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 15,
              marginTop: 15,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Start Date"
                value={bookedStart || null}
                format="DD/MM/YYYY hh:mm A"
                onChange={handleBookedStartChange}
                slotProps={{
                  textField: {
                    variant: "filled",
                    fullWidth: true,
                    InputProps: {
                      disableUnderline: true,
                    },
                  },
                }}
              />

              <DateTimePicker
                label="End Date"
                value={bookedFinish || null}
                format="DD/MM/YYYY hh:mm A"
                onChange={handleBookedFinishChange}
                slotProps={{
                  textField: {
                    variant: "filled",
                    fullWidth: true,
                    InputProps: {
                      disableUnderline: true,
                    },
                  },
                }}
              />
            </LocalizationProvider>
            <IconButton
              onClick={() => {
                additionalDates.push({
                  start: null,
                  end: null,
                  photographer: "",
                  additionalPhotographers: [],
                  operator: "",
                  additionalOperators: [],
                  stylist: "",
                  additionalStylists: [],
                });
                setAdditionalDates([...additionalDates]);
                firebase
                  .firestore()
                  .collection("JobData")
                  .doc(jobData.id)
                  .update({ additionalDates });
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
          <AdditionalDates
            firebase={firebase}
            jobData={jobData}
            additionalDates={additionalDates}
            setAdditionalDates={setAdditionalDates}
          />
          <DueDateExpressContainer
            isMobile={isMobile}
            dueDate={dueDate}
            setDueDate={setDueDate}
            firebase={firebase}
            jobData={jobData}
            express={express}
            setExpress={setExpress}
            expressDueDate={expressDueDate}
            setExpressDueDate={setExpressDueDate}
            canEdit={canEdit}
          />
          <PrefixSelector
            isMobile={isMobile}
            canEdit={canEdit}
            firebase={firebase}
            jobData={jobData}
            eventPrefix={eventPrefix}
            eventPrefixCustom={eventPrefixCustom}
            setEventPrefix={setEventPrefix}
            setEventPrefixCustom={setEventPrefixCustom}
          />
          <JobNumber
            jobNumber={jobNumber}
            setJobNumber={setJobNumber}
            isMobile={isMobile}
            handleJobNumberChange={handleJobNumberChange}
            assignJobNumberDisabled={assignJobNumberDisabled}
            assignJobNumberText={assignJobNumberText}
            setAssignJobNumberText={setAssignJobNumberText}
            jobType={jobType}
            client={client}
            multipleDays={multipleDays}
            firebase={firebase}
            jobData={jobData}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 0,
            }}
          >
            <FormControl
              fullWidth
              sx={{ marginTop: 2, marginRight: 2 }}
              variant="filled"
            >
              <InputLabel id="job-type">Job Type</InputLabel>
              <Select
                disableUnderline
                labelId="job-type-select"
                value={jobType || ""}
                label="Job Type"
                error={jobType ? false : true}
                onChange={(e) => {
                  if (!canEdit) return;
                  setJobType(e.target.value);
                  let jobTypeShort = e.target.value.match(/^.*\((-.*)\)/)[1];
                  updateJobName(
                    jobNumber,
                    client,
                    multipleDays,
                    e.target.value
                  );
                  firebase
                    .firestore()
                    .collection("JobData")
                    .doc(jobData.id)
                    .update({ jobTypeFull: e.target.value }, { merge: true })
                    .catch((error) => {
                      console.log(error);
                    });
                  firebase
                    .firestore()
                    .collection("JobData")
                    .doc(jobData.id)
                    .update({ jobTypeShort: jobTypeShort }, { merge: true })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                {jobTypes.map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Multiple Days"
              value={multipleDays || ""}
              onChange={handleMultipleDaysChange}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              sx={{ marginTop: 2, marginLeft: 2 }}
            />
          </div>
          {jobType?.includes("-L") ||
          jobType?.includes("-LC") ||
          jobType?.includes("-PC") ? (
            <>
              <FormControl
                fullWidth
                sx={{ marginTop: 2, marginRight: 2, marginBottom: 3 }}
                variant="filled"
              >
                <InputLabel id="day-type-label">Day Type</InputLabel>
                <Select
                  labelId="day-type-label"
                  value={selectedDayType}
                  onChange={handleDropdownChange}
                  disableUnderline
                >
                  <MenuItem value="">&zwnj;</MenuItem>
                  <MenuItem value="Life - Full Day">Life - Full Day</MenuItem>
                  <MenuItem value="Life - Half Day">Life - Half Day</MenuItem>
                  <MenuItem value="Content - Full Day">
                    Content - Full Day
                  </MenuItem>
                  <MenuItem value="Content - Half Day">
                    Content - Half Day
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <div style={{ display: "flex", marginTop: 0 }}>
                <FormControl
                  fullWidth
                  sx={{ marginTop: 2, marginRight: 2 }}
                  variant="filled"
                >
                  <InputLabel id="day-type">Life - Day Length</InputLabel>
                  <Select
                    disableUnderline
                    labelId="day-type-select"
                    error={creativeDayType || dayType ? false : true}
                    value={dayType || ""}
                    label="Life - Day Length"
                    onChange={(e) => {
                      if (!canEdit) return;
                      setDayType(e.target.value);
                      firebase
                        .firestore()
                        .collection("JobData")
                        .doc(jobData.id)
                        .update({ dayType: e.target.value }, { merge: true })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    <MenuItem key={"blank"} value={""}>
                      &zwnj;
                    </MenuItem>
                    {dayTypes.map((s) => (
                      <MenuItem key={s} value={s}>
                        {s}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ marginTop: 2, marginLeft: 2 }}
                  variant="filled"
                >
                  <InputLabel id="content-day-type">
                    Content - Day Length
                  </InputLabel>
                  <Select
                    disableUnderline
                    labelId="content-day-type-select"
                    value={creativeDayType || ""}
                    error={creativeDayType || dayType ? false : true}
                    label="Content - Day Length"
                    onChange={(e) => {
                      if (!canEdit) return;
                      setCreativeDayType(e.target.value);
                      firebase
                        .firestore()
                        .collection("JobData")
                        .doc(jobData.id)
                        .update(
                          { creativeDayType: e.target.value },
                          { merge: true }
                        )
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    <MenuItem key={"blank"} value={""}>
                      &zwnj;
                    </MenuItem>

                    {dayTypes.map((s) => (
                      <MenuItem key={s} value={s}>
                        {s}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div> */}
            </>
          ) : null}
          <TextField
            fullWidth
            label="Comments"
            value={comments || ""}
            onChange={handleCommentsChange}
            variant="filled"
            InputProps={{ disableUnderline: true }}
            sx={{ marginTop: 2 }}
          />
          <div style={{ display: "flex", marginTop: 0 }}>
            <TextField
              fullWidth
              label="Images Scheduled"
              variant="filled"
              InputProps={{ disableUnderline: true }}
              value={imagesScheduledToBeShot || ""}
              onChange={handleImagesScheduledChange}
              sx={{ marginTop: 2 }}
            />
          </div>
          <div style={{ display: "none", marginTop: 0 }}>
            <TextField
              fullWidth
              label="year"
              variant="filled"
              InputProps={{ disableUnderline: true }}
              value={year || ""}
              onChange={handleYearChange}
              sx={{ marginTop: 2 }}
            />
          </div>
          <CameraSettings
            firebase={firebase}
            jobData={jobData}
            clientData={jobClientData}
          />
          {/* <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 25,
            }}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noRetouching || false}
                    onChange={(e) => {
                      firebase
                        .firestore()
                        .collection("JobData")
                        .doc(jobData.id)
                        .update({ noRetouching: e.target.checked });
                    }}
                  />
                }
                label="No Retouching"
              />
            </FormGroup>
          </div> */}
          <StaffContainer
            firebase={firebase}
            jobData={jobData}
            checkIfStaffAssigned={checkIfStaffAssigned}
            event={event}
            events={events}
            findStaffAvatar={findStaffAvatar}
            photographer={photographer}
            setPhotographer={setPhotographer}
            additionalPhotographers={additionalPhotographers}
            setAdditionalPhotographers={setAdditionalPhotographers}
            stylist={stylist}
            setStylist={setStylist}
            additionalStylists={additionalStylists}
            setAdditionalStylists={setAdditionalStylists}
            operator={operator}
            setOperator={setOperator}
            additionalOperators={additionalOperators}
            setAdditionalOperators={setAdditionalOperators}
            addAdditionalStaff={addAdditionalStaff}
            canEdit={canEdit}
            staffData={staffData}
            jobType={jobType}
            isEventTomorrow={isEventTomorrow}
            client={client}
            sendAddedNotification={sendAddedNotification}
            sendNotificationToUID={sendNotificationToUID}
            findStaff={findStaff}
            sendRemovedNotification={sendRemovedNotification}
            additionalDates={additionalDates}
            theme={theme}
          />
          {additionalDates &&
            additionalDates.map((ad, index) => (
              <AdditionalDatesStaff
                ad={ad}
                index={index}
                firebase={firebase}
                jobData={jobData}
                checkIfStaffAssigned={checkIfStaffAssigned}
                event={event}
                events={events}
                findStaffAvatar={findStaffAvatar}
                addAdditionalStaff={addAdditionalStaffToAdditionalDate}
                canEdit={canEdit}
                staffData={staffData}
                jobType={jobType}
                isEventTomorrow={isEventTomorrow}
                client={client}
                sendAddedNotification={sendAddedNotification}
                sendNotificationToUID={sendNotificationToUID}
                findStaff={findStaff}
                sendRemovedNotification={sendRemovedNotification}
                additionalDates={additionalDates}
                theme={theme}
              />
            ))}
          <Divider style={{ marginTop: 25 }} />
          <SpecialRequirementsContainer
            value={eventNotes}
            setValue={setEventNotes}
            field="eventNotes"
            label="Event Notes (will only show up in calendar, subject to space"
            firebase={firebase}
            updateFSNow={updateFSNow}
            jobData={jobData}
            marginTop={25}
            canEdit={canEdit}
          />
          <Divider style={{ marginTop: 70 }} />

          <BriefContainer
            jobData={jobData}
            firebase={firebase}
            updateFSNow={updateFSNow}
            canEdit={canEdit}
            theme={theme}
          />
          <NotesContainerContainer
            jobData={jobData}
            clientData={jobClientData}
            firebase={firebase}
            theme={theme}
          />
          <MarkdownNotesEditorCalendar
            isMobile={isMobile}
            label="Invoicing Notes"
            jobValue={invoicingSpecialRequirements}
            setJobValue={(e) => {
              if (!canEdit) return;
              setInvoicingSpecialRequirements(e);
              updateJobData(
                firebase,
                jobData.id,
                "invoicingSpecialRequirements",
                e
              );
            }}
            clientValue={invoicingNotes}
            setClientValue={(e) => {
              if (!canEdit) return;
              setInvoicingNotes(e);
              updateClientData(firebase, jobClientData.id, "invoicingNotes", e);
            }}
            canEdit={canEdit}
            theme={theme}
          />
          <ModelsContainer
            firebase={firebase}
            jobData={jobData}
            models={models}
            setModels={setModels}
            canEdit={canEdit}
            theme={theme}
          />
          <HMUContainer
            firebase={firebase}
            jobData={jobData}
            hmus={hmus}
            setHmus={setHmus}
            canEdit={canEdit}
            theme={theme}
          />
          <div style={{ marginTop: 40 }}>
            <div
              style={{
                marginLeft: 5,
                marginBottom: 10,
                color:
                  theme === "dark"
                    ? "rgba(255,255,255,0.6)"
                    : "rgba(0,0,0,0.6)",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontWeight: 400,
                fontSize: "1rem",
                lineHeight: "1.4375em",
                letterSpacing: "0.00938em",
              }}
            >
              Attachments
            </div>
            <div
              style={{
                marginLeft: 2,
                width: "99.5%",
                height: 300,
                background: "rgba(0,0,0,0.01)",
                border:
                  theme === "dark"
                    ? "1px solid rgba(230,230,230,0.3)"
                    : "1px solid rgba(25,25,25,0.3)",
                borderRadius: 5,
                overflowY: "auto",
              }}
            >
              {clientAttachments.map((a, index) => (
                <Attachment
                  key={`${a.fileName}-${a.url}-${index}`}
                  index={index}
                  attachment={a}
                  deleteAttachment={false}
                  theme={theme}
                />
              ))}
              {attachments.map((a, index) => (
                <Attachment
                  key={`${a.fileName}-${a.url}-${index}`}
                  index={index}
                  attachment={a}
                  deleteAttachment={deleteAttachment}
                  theme={theme}
                />
              ))}
            </div>
          </div>
        </>
      ) : isPostProductionJob ? (
        <>
          {!idList ? (
            <>
              <Button
                variant="contained"
                sx={{ width: 250, height: 50, fontSize: 18 }}
                onClick={() => {
                  lists[3].items.push(jobData.id);

                  const noDupes = [...new Set(lists[3].items)];
                  lists[3].items = noDupes;

                  firebase
                    .firestore()
                    .collection("AdminCollection")
                    .doc("lists")
                    .update({ lists: lists });
                }}
              >
                Start Editing
              </Button>
              <Divider
                style={{
                  // display: spreadsheetURL ? "block" : "none",
                  marginTop: 30,
                  marginBottom: 20,
                }}
              />
            </>
          ) : null}

          <ClientSelector
            clientData={clientData}
            client={client}
            setClient={setClient}
            updateJobName={updateJobName}
            firebase={firebase}
            jobData={jobData}
            jobNumber={jobNumber}
            multipleDays={multipleDays}
            jobType={jobType}
            canEdit={canEdit}
            setDueDate={setDueDate}
          />
          <Divider
            style={{
              display: spreadsheetURL ? "block" : "none",
              marginTop: 25,
              marginBottom: 20,
            }}
          />
          <div
            style={{
              display: spreadsheetURL ? "flex" : "none",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              style={{
                width: 180,
                height: 40,
                fontSize: 12,
              }}
              onClick={handleClientSpreadsheetButtonClick}
            >
              {clientSpreadsheetButtonText}
            </Button>
          </div>
          <Divider style={{ marginTop: 25, marginBottom: 20 }} />

          <DueDateExpressContainer
            isMobile={isMobile}
            dueDate={dueDate}
            setDueDate={setDueDate}
            firebase={firebase}
            jobData={jobData}
            express={express}
            setExpress={setExpress}
            expressDueDate={expressDueDate}
            setExpressDueDate={setExpressDueDate}
            canEdit={canEdit}
          />
          <JobNumber
            jobNumber={jobNumber}
            setJobNumber={setJobNumber}
            isMobile={isMobile}
            handleJobNumberChange={handleJobNumberChange}
            assignJobNumberDisabled={assignJobNumberDisabled}
            assignJobNumberText={assignJobNumberText}
            setAssignJobNumberText={setAssignJobNumberText}
            jobType={jobType}
            client={client}
            multipleDays={multipleDays}
            firebase={firebase}
            jobData={jobData}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 0,
            }}
          >
            <FormControl
              fullWidth
              sx={{ marginTop: 2, marginRight: 2 }}
              variant="filled"
            >
              <InputLabel id="job-type">Job Type</InputLabel>
              <Select
                disableUnderline
                labelId="job-type-select"
                value={jobType || ""}
                label="Job Type"
                error={jobType ? false : true}
                onChange={(e) => {
                  if (!canEdit) return;
                  setJobType(e.target.value);
                  let jobTypeShort = e.target.value.match(/^.*\((-.*)\)/)[1];
                  updateJobName(
                    jobNumber,
                    client,
                    multipleDays,
                    e.target.value
                  );
                  firebase
                    .firestore()
                    .collection("JobData")
                    .doc(jobData.id)
                    .update({ jobTypeFull: e.target.value }, { merge: true });
                  firebase
                    .firestore()
                    .collection("JobData")
                    .doc(jobData.id)
                    .update({ jobTypeShort: jobTypeShort }, { merge: true })
                    .catch((error) => {
                      console.log(error);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                {jobTypes.map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Multiple Days"
              value={multipleDays || ""}
              onChange={(e) => {
                if (!canEdit) return;
                setMultipleDays(e.target.value);
                updateJobName(jobNumber, client, e.target.value, jobType);
                updateFS(firebase, jobData.id, "multipleDays", e.target.value);
              }}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              sx={{ marginTop: 2, marginLeft: 2 }}
            />
          </div>
          <SpecialRequirementsContainer
            value={invoicingSpecialRequirements}
            setValue={setInvoicingSpecialRequirements}
            field="invoicingSpecialRequirements"
            label="Invoicing Special Requirements"
            firebase={firebase}
            updateFSNow={updateFSNow}
            jobData={jobData}
            marginTop={20}
            canEdit={canEdit}
          />
          <SpecialRequirementsContainer
            value={retouchingSpecialRequirements}
            setValue={setRetouchingSpecialRequirements}
            field="retouchingSpecialRequirements"
            label="Retouching Special Requirements"
            firebase={firebase}
            updateFSNow={updateFSNow}
            jobData={jobData}
            marginTop={60}
            canEdit={canEdit}
          />
          <div style={{ marginTop: 60 }}>&nbsp;</div>
        </>
      ) : (
        <>
          <SpecialEventContainer
            firebase={firebase}
            jobData={jobData}
            staffData={staffData}
            title={title}
            setTitle={setTitle}
            recurringEvent={recurringEvent}
            setRecurringEvent={setRecurringEvent}
            rrule={rrule}
            setRrule={setRrule}
            exDate={exDate}
            setExDate={setExDate}
            currentUser={currentUser}
            updateFSNow={updateFSNow}
            bookedStart={bookedStart}
            setBookedStart={setBookedStart}
            bookedFinish={bookedFinish}
            setBookedFinish={setBookedFinish}
            canEdit={canEdit}
            events={events}
            event={event}
            photographer={photographer}
            setPhotographer={setPhotographer}
            additionalPhotographers={additionalPhotographers}
            setAdditionalPhotographers={setAdditionalPhotographers}
            eventNotes={eventNotes}
            setEventNotes={setEventNotes}
            isMobile={isMobile}
            eventPrefix={eventPrefix}
            eventPrefixCustom={eventPrefixCustom}
            setEventPrefix={setEventPrefix}
            setEventPrefixCustom={setEventPrefixCustom}
            findStaffAvatar={findStaffAvatar}
          />
        </>
      )}
      {isAdmin ? (
        <EventTrackingContainer
          tracking={tracking}
          staffData={staffData}
          theme={theme}
        />
      ) : null}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        {canEdit ? (
          <>
            <Button
              variant="contained"
              color="error"
              style={{
                width: 250,
                height: 40,
                display: deleteJobButtonShow ? "block" : "none",
              }}
              onClick={deleteJob}
            >
              DELETE JOB
            </Button>
            <Button
              variant="contained"
              color="error"
              style={{
                width: 250,
                height: 40,
                display: confirmDeleteJobButtonShow ? "block" : "none",
              }}
              onClick={confirmDeleteJob}
            >
              {confirmDeleteJobButtonText}
            </Button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default JobTab;

function sortNotes(notes) {
  let newNotes = [...notes];
  newNotes.sort((a, b) => {
    var keyA = new Date(a.timestamp),
      keyB = new Date(b.timestamp);
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  let tmp = [];

  newNotes.forEach((note, index) => {
    if (note.tags.includes("Pre-Production")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Photographer")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Videographer")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Operator")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Stylist")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Retoucher")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });

  return tmp;
}

const NotesContainerContainer = ({
  jobData,
  clientData,
  firebase,
  isMobile,
  theme,
}) => {
  const titleContainerStyle = {
    marginTop: 20,
    marginLeft: 5,
    marginBottom: 5,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const [sortedNotes, setSortedNotes] = useState([]);

  useEffect(() => {
    let tmp = [];

    jobData?.notes?.forEach((note) => {
      tmp.push(note);
    });
    clientData?.notes?.forEach((note) => {
      tmp.push(note);
    });
    setSortedNotes(sortNotes(tmp));
    // setSortedNotes(sortNotes([...jobData.notes, ...clientData.notes]));
  }, [jobData, clientData]);

  const [newNoteModalOpen, setNewNoteModalOpen] = useState(false);

  const [newNote, setNewNote] = useState(true);

  const [newSelectedNotesFilter, setNewSelectedNotesFilter] = useState([]);
  const [newSelectedNotesJobTypeFilter, setNewSelectedNotesJobTypeFilter] =
    useState([]);
  const [newNoteKind, setNewNoteKind] = useState("");
  const [newNotesText, setNewNotesText] = useState("");
  const [newNotesID, setNewNotesID] = useState(null);

  const handleFilterChange = (event, newFilter) => {
    setNewSelectedNotesFilter(newFilter);
  };

  const handleJobTypeFilterChange = (event, newFilter) => {
    setNewSelectedNotesJobTypeFilter(newFilter);
  };

  const [newNoteErrorDialogOpen, setNewNoteErrorDialogOpen] = useState(false);
  const handlenewNoteErrorDialogClose = () => setNewNoteErrorDialogOpen(false);

  const updateNotes = async () => {
    let err = false;
    if (newSelectedNotesFilter.length === 0) err = true;
    if (!newNoteKind) err = true;
    if (!newNotesText) err = true;

    if (err) {
      setNewNoteErrorDialogOpen(true);
      return;
    }
    const obj = {
      id: uuidv4(),
      kind: newNoteKind,
      tags: newSelectedNotesFilter,
      jobTypes: newSelectedNotesJobTypeFilter || [],
      text: newNotesText,
      timestamp: DateTime.now().setZone("Australia/Sydney").toUTC().toISO(),
    };

    if (newNoteKind === "Job") {
      if (!jobData.notes) jobData.notes = [];
      if (newNote) {
        jobData.notes.push(obj);
      } else {
        jobData.notes.forEach((note, index) => {
          if (!note.jobTypes) note.jobTypes = [];
          if (note.id === newNotesID) {
            jobData.notes[index] = obj;
          }
        });
      }
      await firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ notes: jobData.notes })
        .catch((e) => {
          console.log(e);
        });
    } else if (newNoteKind === "Permanent") {
      if (!clientData.notes) clientData.notes = [];
      if (newNote) {
        clientData.notes.push(obj);
      } else {
        clientData.notes.forEach((note, index) => {
          if (!note.jobTypes) note.jobTypes = [];
          if (note.id === newNotesID) {
            clientData.notes[index] = obj;
          }
        });
      }
      await firebase
        .firestore()
        .collection("ClientDatabase")
        .doc(clientData.id)
        .update({ notes: clientData.notes })
        .catch((e) => {
          console.log(e);
        });
    }

    setNewNotesText("");
    setNewNoteKind("");
    setNewSelectedNotesFilter([]);
    setNewSelectedNotesJobTypeFilter([]);
    setNewNoteModalOpen(false);
    setNewNote(true);
    setNewNotesID(null);
  };

  const filterButtonStyles = {
    fontSize: 10.5,
    fontWeight: "bold",
    padding: 0.8,
    border: `1px solid ${theme === "dark" ? "#3A3A3A" : "#DCDCDC"} !important`,
    borderRadius: "4px !important",
  };

  const [jobTypes] = useAtom(jobTypesAtom);

  return (
    <div id="editNoteContainer">
      <Dialog
        open={newNoteErrorDialogOpen}
        onClose={handlenewNoteErrorDialogClose}
      >
        <DialogContent>
          <DialogContentText>
            Please ensure there is atleast one tag selected
          </DialogContentText>
          <DialogContentText>
            Please ensure you select Permanent or Job
          </DialogContentText>
          <DialogContentText>Please ensure write a note</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlenewNoteErrorDialogClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Slide direction="right" in={newNoteModalOpen} mountOnEnter unmountOnExit> */}
      <Modal
        open={newNoteModalOpen}
        onClose={() => {
          // setEmailModalOpen(false);
          // setEmailKind("");
        }}
        mountOnEnter
        unmountOnExit
      >
        <div
          style={{
            background: theme === "dark" ? "#1f1f1f" : "#ffffff",
            borderRadius: 8,
            // display: "flex",
            // flexDirection: "column",
            padding: 30,
            margin: 20,
            height: "90vh",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {/* <span>{newNote ? "New Note" : "Edit Note"}</span> */}
          <Button
            onClick={updateNotes}
            variant="outlined"
            sx={{ position: "absolute", top: 20, right: 20 }}
          >
            {newNote ? "Add New Note" : "Save Note"}
          </Button>
          <ToggleButtonGroup
            value={newSelectedNotesFilter}
            onChange={handleFilterChange}
            sx={{
              flexWrap: "wrap",
              justifyContent: "flex-start",
              gap: 0.8,
              marginTop: 2,
              marginBottom: 1,
            }}
          >
            <ToggleButton
              value="Pre-Production"
              size="small"
              sx={filterButtonStyles}
            >
              Pre-Production
            </ToggleButton>
            <ToggleButton
              value="Photographer"
              size="small"
              sx={filterButtonStyles}
            >
              Photographer
            </ToggleButton>
            <ToggleButton
              value="Videographer"
              size="small"
              sx={filterButtonStyles}
            >
              Videographer
            </ToggleButton>
            <ToggleButton value="Operator" size="small" sx={filterButtonStyles}>
              Operator
            </ToggleButton>
            <ToggleButton value="Stylist" size="small" sx={filterButtonStyles}>
              Stylist
            </ToggleButton>
            <ToggleButton
              value="Retoucher"
              size="small"
              sx={filterButtonStyles}
            >
              Retoucher
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            value={newNoteKind}
            sx={{
              marginTop: 1.5,
              marginBottom: newNoteKind === "Permanent" ? 1 : 2,
              flexWrap: "wrap",
              justifyContent: "flex-start",
              gap: 0.8,
            }}
          >
            <ToggleButton
              value="Permanent"
              size="small"
              sx={{
                ...filterButtonStyles,
                backgroundColor:
                  newNoteKind === "Permanent"
                    ? theme === "dark"
                      ? "rgb(130, 165, 200) !important"
                      : "rgb(148, 195, 245) !important"
                    : "",
              }}
              onClick={() => {
                if (newNote) {
                  setNewNoteKind("Permanent");
                }
              }}
            >
              Permanent
            </ToggleButton>
            <ToggleButton
              value="Job"
              size="small"
              sx={{
                ...filterButtonStyles,
                backgroundColor:
                  newNoteKind === "Job"
                    ? theme === "dark"
                      ? "rgb(130, 200, 165) !important"
                      : "rgb(148, 245, 195) !important"
                    : "",
              }}
              onClick={() => {
                if (newNote) {
                  setNewNoteKind("Job");
                }
              }}
            >
              Job
            </ToggleButton>
          </ToggleButtonGroup>
          {newNoteKind === "Permanent" ? (
            <ToggleButtonGroup
              value={newSelectedNotesJobTypeFilter}
              onChange={handleJobTypeFilterChange}
              sx={{
                // marginTop: 3,
                // marginBottom: 3,
                flexWrap: "wrap",
                justifyContent: "flex-start",
                gap: 0.8,
                marginTop: 1.5,
                marginBottom: 3,
              }}
            >
              {jobTypes &&
                jobTypes.map((jt) => {
                  const combined = `${jt.name} (-${jt.nameShort})`;
                  return (
                    <ToggleButton
                      key={jt.id}
                      value={jt.id}
                      size="small"
                      sx={filterButtonStyles}
                    >
                      {combined}
                    </ToggleButton>
                  );
                })}
            </ToggleButtonGroup>
          ) : null}
          <div style={{ position: "relative", height: "90%" }}>
            <MDEditor
              value={newNotesText}
              // minHeight={400}
              // maxHeight={900}
              height={"90%"}
              onChange={(e) => {
                setNewNotesText(e);
              }}
              commands={[
                commands.bold,
                commands.italic,
                commands.strikethrough,
                commands.hr,
                commands.title4,
                commands.divider,
                commands.link,
                commands.quote,
                commands.code,
                commands.codeBlock,
                commands.divider,
                commands.unorderedListCommand,
                commands.orderedListCommand,
              ]}
              extraCommands={[]}
            />
          </div>
        </div>
      </Modal>
      {/* </Slide> */}
      <div style={titleContainerStyle}>
        <span>Notes</span>
        <IconButton
          onClick={() => {
            setNewNoteModalOpen(!newNoteModalOpen);
            setNewNotesText("");
            setNewNoteKind("");
            setNewSelectedNotesFilter([]);
            setNewNote(true);
            setNewNotesID(null);
          }}
          style={{ marginRight: -8 }}
        >
          {!newNoteModalOpen ? <AddIcon /> : <RemoveIcon />}
        </IconButton>
      </div>

      <NotesContainer
        firebase={firebase}
        jobData={jobData}
        clientData={clientData}
        sortedNotes={sortedNotes}
        selectedNotesFilter={[]}
        isMobile={isMobile}
        modalWidth={"100%"}
        theme={theme}
        newNoteModalOpen={newNoteModalOpen}
        setNewNoteModalOpen={setNewNoteModalOpen}
        setNewSelectedNotesFilter={setNewSelectedNotesFilter}
        setNewSelectedNotesJobTypeFilter={setNewSelectedNotesJobTypeFilter}
        setNewNoteKind={setNewNoteKind}
        setNewNotesText={setNewNotesText}
        setNewNotesID={setNewNotesID}
        setNewNote={setNewNote}
        jobTab={true}
        clientTab={false}
        shootTab={false}
        kind={"ShowAll"}
      />
    </div>
  );
};

const Attachment = ({ attachment, index, deleteAttachment, theme }) => {
  return (
    <div
      className="attachmentItem"
      style={{
        margin: 5,
        padding: 4,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 5,
        borderBottom:
          theme === "dark"
            ? "1px solid rgba(230,230,230,0.3)"
            : "1px solid rgba(25,25,25,0.3)",

        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: deleteAttachment ? "" : 40,
      }}
    >
      <a
        href={attachment.url}
        rel="noreferrer"
        target="_blank"
        style={{
          color: theme === "dark" ? "rgba(230,230,230,1)" : "rgba(25,25,25,1)",
        }}
      >
        {attachment.fileName}
      </a>
      {deleteAttachment ? (
        <IconButton
          onClick={() => {
            deleteAttachment(attachment, index);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ) : null}
    </div>
  );
};
const sendRemovedNotification = (
  eventStart,
  id,
  client,
  jobType,
  isEventTomorrow,
  sendNotificationToUID
) => {
  if (id === "8jXVYoCymxHLQngklbWE") {
    if (isEventTomorrow(new Date(eventStart).toISOString())) {
      try {
        sendNotificationToUID({
          id: id,
          title: `REMOVED - ${client} - ${jobType.replace(/ \(.*\)/, "")}`,
          text: `You have been removed from a job tomorrow, please tap to check the calendar.`,
          jumpto: new Date(eventStart).getTime(),
          kind: "notificationNextDayChangesMe",
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
const sendAddedNotification = (
  eventStart,
  id,
  client,
  jobType,
  isEventTomorrow,
  sendNotificationToUID
) => {
  if (id === "8jXVYoCymxHLQngklbWE") {
    if (isEventTomorrow(new Date(eventStart).toISOString())) {
      try {
        sendNotificationToUID({
          id: id,
          title: `ADDED - ${client} - ${jobType.replace(/ \(.*\)/, "")}`,
          text: `You have been added to a job tomorrow, please tap to check the calendar.`,
          jumpto: new Date(eventStart).getTime(),
          kind: "notificationNextDayChangesMe",
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
const findStaff = (option, staffData) => {
  if (option.firstName) return `${option.firstName} ${option.lastName}`;
  for (let i = 0; i < staffData.length; i++) {
    if (staffData[i].id === option) {
      return `${staffData[i].firstName} ${staffData[i].lastName}`;
    }
  }
  return option;
};
const findStaffAvatar = (option, staffData) => {
  for (let i = 0; i < staffData.length; i++) {
    try {
      if (staffData[i].id === option) {
        if (staffData[i].avatar) {
          return staffData[i].avatar;
        }
      }
      if (staffData[i].id === option.id) {
        if (staffData[i].avatar) {
          return staffData[i].avatar;
        }
      }
    } catch (e) {}
  }
  return "";
};
const updateJobData = debounce((firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("JobData")
    .doc(id)
    .update({ [field]: value }, { merge: true })
    .catch((error) => {
      console.log(error);
    });
}, 400);
const updateClientData = debounce((firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("ClientDatabase")
    .doc(id)
    .update({ [field]: value }, { merge: true })
    .catch((error) => {
      console.log(error);
    });
}, 400);
const checkIfStaffAssigned = (option, events, event, jobData) => {
  let recurringStaffEvents = [];
  events.forEach((e) => {
    if (e.extendedProps.recurringEvent) {
      recurringStaffEvents.push(e);
    }
  });
  let recurringStaffEventsRrule = [];
  recurringStaffEvents.forEach((e) => {
    let freq = parseFreq(e.rrule.freq);
    let byweekday = parseByWeekDay(e.rrule.byweekday);

    const rule = new RRule({
      freq: freq,
      interval: e.rrule.interval,
      byweekday: byweekday,
      dtstart: new Date(e.rrule.dtstart),
      until: new Date(e.rrule.until),
    });
    if (e.extendedProps.photographer === option.id) {
      recurringStaffEventsRrule.push({
        event: e,
        staff: e.extendedProps.photographer,
        dates: rule.all(),
      });
    }
  });

  let shouldReturnTrue = false;
  const startDate = event.start;
  // const startDate = event?.start ? event.start.toDate() : new Date(jobData.start);
  recurringStaffEventsRrule.forEach((r) => {
    r.dates.forEach((d) => {
      const repeatDateRaw = new Date(d);
      const repeatDate = `${repeatDateRaw.getFullYear()}-${
        repeatDateRaw.getMonth() + 1
      }-${repeatDateRaw.getDate()}`;

      const date = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;

      if (repeatDate === date) {
        shouldReturnTrue = true;
      }
    });
  });
  if (shouldReturnTrue) {
    return true;
  }
  // console.log(startDate);
  const date = `${startDate.getFullYear()}-${
    startDate.getMonth() + 1
  }-${startDate.getDate()}`;

  for (let i = 0; i < events.length; i++) {
    let e = events[i];
    if (!e.start) continue;
    let start = new Date(e.start);

    let d = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    if (d === date) {
      if (e.extendedProps.photographer === option.id) return true;
      if (e.extendedProps.operator === option.id) return true;
      if (e.extendedProps.stylist === option.id) return true;

      if (e.extendedProps.additionalPhotographers?.includes(option.id))
        return true;
      if (e.extendedProps.additionalOperators?.includes(option.id)) return true;
      if (e.extendedProps.additionalStylists?.includes(option.id)) return true;
    }
  }
  return false;
};
function parseByWeekDay(byweekday) {
  const array = [];
  byweekday.forEach((w) => {
    if (w === "mo") {
      array.push(RRule.MO);
    } else if (w === "tu") {
      array.push(RRule.TU);
    } else if (w === "we") {
      array.push(RRule.WE);
    } else if (w === "th") {
      array.push(RRule.TH);
    } else if (w === "fr") {
      array.push(RRule.FR);
    } else if (w === "sa") {
      array.push(RRule.SA);
    } else if (w === "su") {
      array.push(RRule.SU);
    }
  });
  return array;
}
function parseFreq(freq) {
  if (freq === "weekly") return RRule.WEEKLY;
}
