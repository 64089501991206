import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import {
  CheckCircle,
  Image,
  Folder,
  Dashboard,
  SortByAlpha,
} from "@mui/icons-material";

export const InstructionsDialog = React.memo(({ open, onClose }) => {
  const [showButton, setShowButton] = React.useState(false);

  React.useEffect(() => {
    let timer;
    if (open) {
      setShowButton(false);
      timer = setTimeout(() => {
        setShowButton(true);
      }, 12000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown
      onBackdropClick={() => null}
    >
      <DialogTitle>ITS Image Selects</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          <Typography paragraph>Here's how to make your selects:</Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <Folder />
              </ListItemIcon>
              <ListItemText
                primary="Browse Folders"
                secondary="Click on folders to expand/collapse and view images"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Image />
              </ListItemIcon>
              <ListItemText
                primary="Select Images"
                secondary="Click on any image to add or remove it from your selects"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircle />
              </ListItemIcon>
              <ListItemText
                primary="Confirm Selection"
                secondary="Click 'Confirm Selects' when you're done, this will send your selection off to the production team to start retouching"
              />
            </ListItem>
            <Divider sx={{ marginTop: 3, marginBottom: 4 }} />
            <Typography paragraph>Important info:</Typography>
            <ListItem>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText
                primary="Storyboard Selects"
                secondary="The storyboard will automatically build as you make your selects"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SortByAlpha />
              </ListItemIcon>
              <ListItemText
                primary="Image Order"
                secondary="The images on the storyboard will be in the same order as the shot folders"
              />
            </ListItem>
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (showButton) {
              onClose();
            }
          }}
          variant="contained"
        >
          {showButton ? (
            "Got it"
          ) : (
            <>
              <CircularProgress size={20} color="inherit" />{" "}
              <span style={{ marginLeft: 20 }}>Loading...</span>
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const ResetSelectsDialog = React.memo(({ open, onClose, onReset }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Reset</DialogTitle>
      <DialogContent>
        Are you sure you want to reset your selections?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onReset} color="error" variant="contained">
          Reset
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export const ConfirmSelectsDialog = React.memo(
  ({ open, onClose, onConfirm, selectedCount }) => {
    const [loading, setLoading] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);

    React.useEffect(() => {
      if (open) {
        setLoading(false);
        setConfirmed(false);
      }
    }, [open]);

    React.useEffect(() => {
      const handleBeforeUnload = (e) => {
        if (loading) {
          e.preventDefault();
          e.returnValue = "";
        }
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () =>
        window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [loading]);

    const handleConfirm = async () => {
      setLoading(true);
      await onConfirm();
      setLoading(false);
      setConfirmed(true);
    };

    const handleDialogClose = (event, reason) => {
      if (loading && (reason === "backdropClick" || reason === "escapeKeyDown"))
        return;
      if (!loading) onClose();
    };

    const handleOkay = () => {
      onClose();
    };

    return (
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Confirm Selects</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          <DialogContentText>
            {loading
              ? "Please wait while your selects are processing"
              : confirmed
              ? `Your ${selectedCount} selects have been confirmed, post production will be emailed automatically and retouching will begin shortly!`
              : `Are you sure you want to confirm your ${selectedCount} selections? Once confirmed, the retouching process will begin and any changes may incur a fee.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <Button color="primary" variant="contained" disabled>
              Processing....
            </Button>
          ) : confirmed ? (
            <Button onClick={handleOkay} color="primary" variant="contained">
              Okay
            </Button>
          ) : (
            <>
              <Button
                onClick={handleDialogClose}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                color="primary"
                variant="contained"
              >
                Confirm Selects
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

export const SelectsMadeDialog = React.memo(({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Selects Already Made</DialogTitle>
      <DialogContent>
        <DialogContentText>
          It appears that your selects have already been confirmed. You can still browse your selects and download the storyboards.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
});
