import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GridInAccordion } from "./Components";
import { useTheme } from "@mui/material/styles";

const EnhancedAccordion = ({
  title,
  images,
  selectedImages,
  lockedSelectedImages,
  onImageSelect,
  gridColumns,
  dimensions,
  itemSize,
  backgroundColor = "#2a2a2a",
  onExpand,
  subfolders = null,
  containerWidth,
  selectsMade,
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (isExpanded) {
      const imagesNeedingLinks = images.filter((img) => !img.directUrl);
      if (imagesNeedingLinks.length > 0) {
        onExpand(imagesNeedingLinks);
      }

      if (subfolders) {
        Object.values(subfolders).forEach((subfolder) => {
          const subfolderImagesNeedingLinks = subfolder.filter(
            (img) => !img.directUrl
          );
          if (subfolderImagesNeedingLinks.length > 0) {
            onExpand(subfolderImagesNeedingLinks);
          }
        });
      }
    }
  };

  const allImages = React.useMemo(() => {
    const subfolderImages = subfolders ? Object.values(subfolders).flat() : [];
    return [...images, ...subfolderImages];
  }, [images, subfolders]);

  const aggregatedCounts = React.useMemo(() => {
    const total = allImages.length;
    const selected = allImages.filter((img) =>
      selectedImages.some((sel) => sel.name === img.name)
    ).length;
    return { total, selected };
  }, [allImages, selectedImages]);

  const handleResetClick = (e) => {
    e.stopPropagation();
    const selectedInAccordion = allImages.filter((img) =>
      selectedImages.some((sel) => sel.name === img.name)
    );
    if (selectedInAccordion.length > 0) {
      onImageSelect(selectedInAccordion, true);
    }
  };

  const updatedDimensions = {
    ...dimensions,
    width: containerWidth || dimensions.width,
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      TransitionProps={{
        unmountOnExit: true,
        timeout: 100,
      }}
      sx={{
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : backgroundColor,
        color: theme.palette.mode === "light" ? "#000" : "#fff",
        marginBottom: "8px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            sx={{ color: theme.palette.mode === "light" ? "#000" : "#fff" }}
          />
        }
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <span>{title}</span>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {!selectsMade ? (
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={handleResetClick}
                sx={{
                  visibility: allImages.some((img) =>
                    selectedImages.some((sel) => sel.name === img.name)
                  )
                    ? "visible"
                    : "hidden",
                }}
              >
                Reset Selects
              </Button>
            ) : null}
            <span>
              {aggregatedCounts.selected}/{aggregatedCounts.total} Selected
            </span>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "8px" }}>
        <GridInAccordion
          images={images}
          selectedImages={selectedImages}
          lockedSelectedImages={lockedSelectedImages}
          onImageSelect={onImageSelect}
          gridColumns={gridColumns}
          dimensions={updatedDimensions}
          itemSize={itemSize}
          selectsMade={selectsMade}
        />

        {subfolders &&
          Object.entries(subfolders).map(([subfolderName, subfolderImages]) => (
            <EnhancedAccordion
              key={subfolderName}
              title={subfolderName}
              images={subfolderImages}
              selectedImages={selectedImages}
              lockedSelectedImages={lockedSelectedImages}
              onImageSelect={onImageSelect}
              gridColumns={gridColumns}
              dimensions={updatedDimensions}
              itemSize={itemSize}
              backgroundColor="#3a3a3a"
              onExpand={onExpand}
              containerWidth={containerWidth}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default EnhancedAccordion;
