import React, { useState, useEffect, useCallback } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  TextField,
  IconButton,
  InputAdornment,
  Modal,
  Box,
  Typography,
  Paper,
  Button,
  Divider,
  CircularProgress,
  Alert,
  Snackbar,
  Drawer,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme as useMuiTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Add as AddIcon,
  Save as SaveIcon,
  Search as SearchIcon,
  Clear as ClearIcon,
  CloudUpload as CloudUploadIcon,
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import { use100vh } from "react-div-100vh";
import { useHistory, useLocation } from "react-router-dom";

import "./App.css";

import ClientTab from "./Calendar/Event Data Modal Components/ClientTab";

const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

const illegalChars = [`'`, "/", ":", '"', "`", ";"];

const ClientListContainer = styled(Paper)(({ theme }) => ({
  width: 430,
  height: "100%",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  borderRadius: 12,
  marginRight: 16,
}));

const ClientDetailsContainer = styled(Paper)(({ theme, isMobile }) => ({
  width: isMobile ? "100%" : "calc(100vw - 515px)",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: 12,
  overflow: "hidden",
}));

const StyledListItem = styled(ListItem)(({ theme, isSelected }) => ({
  borderRadius: 8,
  margin: theme.spacing(0.5, 0),
  backgroundColor: isSelected
    ? theme.palette.mode === "dark"
      ? theme.palette.action.selected
      : theme.palette.primary.light
    : "transparent",
  "&:hover": {
    backgroundColor: isSelected
      ? theme.palette.mode === "dark"
        ? theme.palette.action.selected
        : theme.palette.primary.light
      : theme.palette.action.hover,
  },
  transition: theme.transitions.create(["background-color"], {
    duration: theme.transitions.duration.short,
  }),
  cursor: "pointer",
  userSelect: "none",
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  position: "fixed",
  top: 16,
  left: 16,
  zIndex: 1200,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

let firebaseX;

const ClientDatabase = ({ firebase, theme }) => {
  const h = use100vh() - 80;
  firebaseX = firebase;
  const history = useHistory();
  const location = useLocation();

  const muiTheme = useMuiTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [clientDataRaw, setClientDataRaw] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientData, setSelectedClientData] = useState({});
  const [suppliedLinks, setSuppliedLinks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [newClientModal, setNewClientModal] = useState(false);
  const [newClient, setNewClient] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [showHiddenClients, setShowHiddenClients] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const clientFromUrl = params.get("client");
    if (clientFromUrl) {
      setSelectedClient(clientFromUrl);
    }
  }, [location.search]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClientSelect = useCallback(
    (client) => {
      setSelectedClient(client);

      const params = new URLSearchParams(location.search);
      if (client) {
        params.set("client", client);
      } else {
        params.delete("client");
      }

      history.push({
        pathname: location.pathname,
        search: params.toString(),
      });

      if (isMobile) {
        setDrawerOpen(false);
      }
    },
    [isMobile, history, location.pathname, location.search]
  );

  const handleCreateClient = async () => {
    if (!newClient.trim()) return;

    setIsCreating(true);

    try {
      const sanitizedClientName = newClient
        .split("")
        .map((char) => (illegalChars.includes(char) ? " " : char))
        .join("")
        .trim();

      const res = await fetch(
        "https://us-central1-its-connect-main.cloudfunctions.net/checkIfClientNameIsAvailable",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            clientName: sanitizedClientName,
          }),
        }
      ).then((response) => response.json());

      if (res.exists) {
        alert("A client with this name already exists");
        setIsCreating(false);
        return;
      }

      const clientObj = {
        brand: sanitizedClientName,
        brandLowerCase: sanitizedClientName.toLowerCase(),
        turnAround: 5,
        PSF: 15,
        contacts: [],
        paymentTerms: "COD",
        isHidden: false, // Add isHidden field
      };

      const docRef = await firebase
        .firestore()
        .collection("ClientDatabase")
        .add(clientObj);

      setSelectedClient(docRef.id);

      setNewClient("");
      setNewClientModal(false);
    } catch (error) {
      console.error("Error creating client:", error);
      alert("Failed to create client. Please try again.");
    } finally {
      setIsCreating(false);
    }
  };

  const toggleHiddenClients = () => {
    setShowHiddenClients(!showHiddenClients);
  };

  const handleHideClient = async (clientId) => {
    try {
      await firebase
        .firestore()
        .collection("ClientDatabase")
        .doc(clientId)
        .set({ isHidden: true }, { merge: true });
    } catch (error) {
      console.error("Error hiding client:", error);
    }
  };

  const exportContacts = () => {
    const contacts = [];

    clientData.forEach((client) => {
      if (!client.contacts) return;

      client.contacts.forEach((contact) => {
        if (!contact.email) return;

        const nameParts = contact.name.split(/[ .]/);
        let firstName = "",
          lastName = "";

        if (nameParts.length === 1) {
          firstName = contact.name;
        } else if (nameParts.length === 2) {
          [firstName, lastName] = nameParts;
        } else if (nameParts.length > 2) {
          firstName = nameParts[0];
          lastName = nameParts.slice(1).join(" ");
        }

        contacts.push({
          email: contact.email,
          "First Name": firstName,
          "Last Name": lastName,
        });
      });
    });

    const csvContent = convertToCSV(contacts);
    downloadCSV("Client_Database.csv", csvContent);
  };

  const convertToCSV = (data) => {
    if (data.length === 0) return "";

    const header = Object.keys(data[0]);
    const headerString = header.join(",");

    const rows = data.map((row) => {
      return header
        .map((field) => {
          const value = row[field] || "";

          return `"${value.toString().replace(/"/g, '""')}"`;
        })
        .join(",");
    });

    return [headerString, ...rows].join("\n");
  };

  const downloadCSV = (filename, text) => {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  useEffect(() => {
    setLoading(true);
    const unsub = firebase
      .firestore()
      .collection("ClientDatabase")
      .orderBy("brand", "asc")
      .onSnapshot(
        (collection) => {
          const cd = collection.docs.map((client) => {
            const raw = client.data();
            return { ...raw, id: client.id };
          });
          setClientDataRaw(cd);
          setClientData(
            cd.filter((client) => showHiddenClients || !client.isHidden)
          );
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching clients:", error);
          setNotification({
            open: true,
            message: "Failed to load clients. Please try again.",
            severity: "error",
          });
          setLoading(false);
        }
      );

    return () => {
      try {
        unsub();
      } catch (e) {
        console.error("Error unsubscribing:", e);
      }
    };
  }, [firebase, showHiddenClients]);

  useEffect(() => {
    const client = clientData.find((c) => c.id === selectedClient);
    setSelectedClientData(client || {});
  }, [selectedClient, clientData]);

  useEffect(() => {
    if (selectedClientData?.suppliedLinks) {
      setSuppliedLinks([...selectedClientData.suppliedLinks].reverse());
    } else {
      setSuppliedLinks([]);
    }
  }, [selectedClientData]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: h,
        padding: 2,
        gap: 2,
        overflow: "hidden",
      }}
    >
      {isMobile && (
        <MenuButton
          color="primary"
          onClick={toggleDrawer}
          aria-label="Open client list"
        >
          <MenuIcon />
        </MenuButton>
      )}

      {isMobile ? (
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "85%",
              maxWidth: 450,
              boxSizing: "border-box",
              borderTopRightRadius: 16,
              borderBottomRightRadius: 16,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 2,
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <IconButton onClick={toggleDrawer} sx={{ mr: 1 }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">Clients</Typography>
          </Box>

          <Box
            sx={{ flexGrow: 1, overflow: "auto", height: "calc(100% - 64px)" }}
          >
            <ClientList
              clientData={clientData}
              setClientData={setClientData}
              clientDataRaw={clientDataRaw}
              loading={loading}
              handleClientSelect={handleClientSelect}
              selectedClient={selectedClient}
              theme={theme}
              onNewClient={() => setNewClientModal(true)}
              onExportContacts={exportContacts}
              onHideClient={handleHideClient}
              showHiddenClients={showHiddenClients}
              toggleHiddenClients={toggleHiddenClients}
            />
          </Box>
        </Drawer>
      ) : (
        <ClientListContainer elevation={3}>
          <ClientList
            clientData={clientData}
            setClientData={setClientData}
            clientDataRaw={clientDataRaw}
            loading={loading}
            handleClientSelect={handleClientSelect}
            selectedClient={selectedClient}
            theme={theme}
            onNewClient={() => setNewClientModal(true)}
            onExportContacts={exportContacts}
            onHideClient={handleHideClient}
            showHiddenClients={showHiddenClients}
            toggleHiddenClients={toggleHiddenClients}
          />
        </ClientListContainer>
      )}

      <ClientDetailsContainer
        elevation={3}
        isMobile={isMobile}
        sx={{
          ml: isMobile ? 0 : undefined,
          mt: isMobile ? 4 : 0,
        }}
      >
        {selectedClientData ? (
          <ClientCardContainer
            selectedClient={selectedClient}
            clientData={clientData}
            firebase={firebase}
            suppliedLinks={suppliedLinks}
            theme={theme}
            isMobile={isMobile}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              px: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                mb: 2,
                textAlign: "center",
                mt: isMobile ? 4 : 0,
              }}
            >
              {loading
                ? "Loading clients..."
                : "Select a client to view details"}
            </Typography>
            {loading && <CircularProgress />}

            {!loading && isMobile && clientData.length > 0 && (
              <Button
                variant="contained"
                startIcon={<MenuIcon />}
                onClick={toggleDrawer}
                sx={{ mt: 2 }}
              >
                Select Client
              </Button>
            )}
          </Box>
        )}
      </ClientDetailsContainer>

      <Modal
        open={newClientModal}
        onClose={() => !isCreating && setNewClientModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "85%" : 400,
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              mb: 1,
            }}
          >
            Create New Client
          </Typography>

          <Divider sx={{ mb: 2 }} />

          <TextField
            label="Client Name"
            fullWidth
            variant="outlined"
            value={newClient}
            onChange={(e) => {
              const sanitizedValue = e.target.value
                .split("")
                .map((char) => (illegalChars.includes(char) ? "" : char))
                .join("");
              setNewClient(sanitizedValue);
            }}
            disabled={isCreating}
            sx={{
              mb: 2,
              "& .MuiInputBase-root": {
                borderRadius: 2,
              },
            }}
            autoFocus
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Button
              onClick={() => {
                setNewClientModal(false);
                setNewClient("");
              }}
              disabled={isCreating}
              variant="outlined"
              sx={{
                flex: 1,
                borderRadius: 2,
                textTransform: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleCreateClient}
              disabled={!newClient.trim() || isCreating}
              startIcon={isCreating ? <CircularProgress size={16} /> : null}
              sx={{
                flex: 1,
                borderRadius: 2,
                textTransform: "none",
              }}
            >
              {isCreating ? "Creating..." : "Create Client"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => setNotification({ ...notification, open: false })}
      >
        <Alert
          severity={notification.severity}
          onClose={() => setNotification({ ...notification, open: false })}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const ClientList = ({
  clientData,
  setClientData,
  clientDataRaw,
  loading,
  handleClientSelect,
  selectedClient,
  theme,
  onNewClient,
  onExportContacts,
  onHideClient,
  showHiddenClients,
  toggleHiddenClients,
}) => {
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event, client) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      client,
    });
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleToggleClientVisibility = async (client) => {
    try {
      await firebaseX
        .firestore()
        .collection("ClientDatabase")
        .doc(client.id)
        .set({ isHidden: !client.isHidden }, { merge: true });
    } catch (error) {
      console.error("Error toggling client visibility:", error);
    } finally {
      handleCloseContextMenu();
    }
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={{ p: 2, display: "flex", gap: 1, justifyContent: "center" }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onNewClient}
          size="small"
          fullWidth
        >
          New Client
        </Button>

        <Button
          variant="outlined"
          startIcon={<SaveIcon />}
          onClick={onExportContacts}
          size="small"
          fullWidth
        >
          Export Contacts
        </Button>
      </Box>
      <Box
        sx={{
          p: 2,
          pt: 0,
          mt: -1,
          display: "flex",
          gap: 1,
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          startIcon={
            showHiddenClients ? <VisibilityOffIcon /> : <VisibilityIcon />
          }
          onClick={toggleHiddenClients}
          size="small"
          fullWidth
        >
          {showHiddenClients ? "Hide Hidden Clients" : "Show Hidden Clients"}
        </Button>
      </Box>

      <Divider />

      <Box sx={{ p: 2 }}>
        <SearchBox
          clientData={clientData}
          setClientData={setClientData}
          clientDataRaw={clientDataRaw}
        />
      </Box>

      <Divider />

      <List sx={{ flex: 1, overflow: "auto", px: 1 }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
            <CircularProgress size={32} />
          </Box>
        ) : clientData.length > 0 ? (
          clientData.map((client) => (
            <StyledListItem
              key={client.id}
              button
              onClick={() => handleClientSelect(client.id)}
              onContextMenu={(event) => handleContextMenu(event, client)}
              isSelected={selectedClient === client.id}
            >
              <ListItemAvatar sx={{ marginRight: 2 }}>
                {client.logo ? (
                  <img
                    src={client.logo}
                    alt={`${client.id} logo`}
                    style={{ width: 55, height: "auto", marginRight: 18 }}
                  />
                ) : (
                  <Avatar
                    sx={{
                      bgcolor:
                        selectedClient === client.id
                          ? "primary.main"
                          : theme === "dark"
                          ? "#444"
                          : "#e0e0e0",
                    }}
                  >
                    {client.brand.charAt(0).toUpperCase()}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {client.brand}
                    {client.isHidden && (
                      <VisibilityOffIcon
                        fontSize="small"
                        sx={{ color: "text.secondary" }}
                      />
                    )}
                  </Box>
                }
                secondary={`${client.paymentTerms || "N/A"} • ${
                  client.turnAround || 0
                } days`}
              />
            </StyledListItem>
          ))
        ) : (
          <Box sx={{ p: 3, textAlign: "center" }}>
            <Typography color="text.secondary">
              No clients match your search
            </Typography>
          </Box>
        )}
      </List>

      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {contextMenu?.client?.isHidden ? (
          <MenuItem
            onClick={() => handleToggleClientVisibility(contextMenu.client)}
          >
            Show Client
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => handleToggleClientVisibility(contextMenu.client)}
          >
            Hide Client
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};

const ClientCardContainer = ({
  selectedClient,
  clientData,
  firebase,
  suppliedLinks,
  theme,
  isMobile,
}) => {
  const client = clientData.find((c) => c.id === selectedClient) || null;

  return (
    <>
      {client ? (
        <ClientCard
          client={client}
          firebase={firebase}
          suppliedLinks={suppliedLinks}
          theme={theme}
          isMobile={isMobile}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Typography variant="h5" sx={{ textAlign: "center", px: 2 }}>
            Select a client to view details
          </Typography>
        </Box>
      )}
    </>
  );
};

const ClientCard = ({ client, firebase, suppliedLinks, theme, isMobile }) => {
  const [logoUploadOpen, setLogoUploadOpen] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [logoUploading, setLogoUploading] = useState(false);

  const handleLogoUpload = async () => {
    if (!logoFile) return;

    setLogoUploading(true);

    try {
      const storageRef = firebase.storage().ref();
      const logoRef = storageRef.child(
        `client-logos/${client.id}/${logoFile.name}`
      );

      await logoRef.put(logoFile);

      const logoUrl = await logoRef.getDownloadURL();

      await updateFSNow(client.id, "logo", logoUrl);

      setLogoUploadOpen(false);
      setLogoFile(null);
    } catch (error) {
      console.error("Error uploading logo:", error);
    } finally {
      setLogoUploading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        overflow: "auto",
        justifyContent: "center",
        width: isMobile ? "96vw" : "100%",
      }}
    >
      <div style={{ width: isMobile ? "90vw" : "60vw", maxWidth: 800 }}>
        <ClientTab
          jobClientData={client}
          updateFS={updateFS}
          firebase={firebase}
          canEdit={true}
          theme={theme}
        />
      </div>

      <Modal
        open={logoUploadOpen}
        onClose={() => !logoUploading && setLogoUploadOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "85%" : 400,
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Upload Client Logo
          </Typography>

          <Box sx={{ mb: 3 }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              sx={{ mb: 2 }}
              disabled={logoUploading}
            >
              Select Image
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setLogoFile(e.target.files[0])}
              />
            </Button>

            {logoFile && (
              <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                Selected: {logoFile.name}
              </Typography>
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button
              onClick={() => setLogoUploadOpen(false)}
              disabled={logoUploading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleLogoUpload}
              disabled={!logoFile || logoUploading}
              startIcon={logoUploading ? <CircularProgress size={16} /> : null}
            >
              {logoUploading ? "Uploading..." : "Upload"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

const SearchBox = ({ clientData, setClientData, clientDataRaw }) => {
  const [inputValue, setInputValue] = useState("");

  const handleInput = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value.length > 0) {
      try {
        const searchTerm = value.toLowerCase();
        const filteredClients = clientDataRaw.filter((client) =>
          client.brand.toLowerCase().includes(searchTerm)
        );
        setClientData([...filteredClients]);
      } catch (error) {
        console.error("Search error:", error);
      }
    } else {
      handleClearSearch();
    }
  };

  const handleClearSearch = () => {
    setInputValue("");
    setClientData([...clientDataRaw]);
  };

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        placeholder="Search clients..."
        value={inputValue}
        onChange={handleInput}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: inputValue && (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleClearSearch} size="small">
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

const updateFSNow = debounce(
  async (id, field, value) => {
    firebaseX
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .set({ [field]: value }, { merge: true });
  },
  0,
  true
);

const updateFS = debounce(async (id, field, value) => {
  firebaseX
    .firestore()
    .collection("ClientDatabase")
    .doc(id)
    .set({ [field]: value }, { merge: true });
}, 800);

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default ClientDatabase;
