import React, { useState, useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const BriefContainer = ({ jobData, firebase, updateFSNow, canEdit, theme }) => {
  const titleContainerStyle = {
    marginTop: 20,
    marginLeft: 5,
    marginBottom: 5,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  // Load existing URLs into state
  const [briefUrls, setBriefUrls] = useState(jobData?.briefUrls ?? []);

  useEffect(() => {
    if (Array.isArray(jobData?.briefUrls)) {
      setBriefUrls(jobData.briefUrls);
    }
  }, [jobData]);

  // We'll calculate what to actually render:
  // If canEdit && the array is empty, show one empty string as a placeholder.
  const displayBriefUrls =
    canEdit && briefUrls.length === 0 ? [""] : briefUrls;

  // If canEdit=false and there's nothing to show, hide this section entirely.
  if (!canEdit && displayBriefUrls.length === 0) {
    return null;
  }

  const handleAddBriefUrl = () => {
    if (!canEdit) return;
    const updated = [...briefUrls, ""];
    setBriefUrls(updated);
    updateFSNow(firebase, jobData.id, "briefUrls", updated);
  };

  const handleRemoveBriefUrl = (index) => {
    if (!canEdit) return;
    const updated = briefUrls.filter((_, i) => i !== index);
    setBriefUrls(updated);
    updateFSNow(firebase, jobData.id, "briefUrls", updated);
  };

  const handleChangeBriefUrl = (index, value) => {
    if (!canEdit) return;
    const updated = [...briefUrls];
    updated[index] = value;
    setBriefUrls(updated);
    updateFSNow(firebase, jobData.id, "briefUrls", updated);
  };

  return (
    <div style={{ marginTop: 20, width: "94%" }}>
      <div style={titleContainerStyle}>
        <span>Briefs</span>
        {canEdit && (
          <IconButton
            onClick={handleAddBriefUrl}
            disabled={!canEdit}
            style={{ marginRight: -8 }}
          >
            <AddIcon />
          </IconButton>
        )}
      </div>

      {displayBriefUrls.map((url, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: 8 }}
        >
          <TextField
            variant="filled"
            fullWidth
            value={url}
            onChange={(e) => handleChangeBriefUrl(index, e.target.value)}
            InputProps={{ disableUnderline: true }}
            sx={{ marginTop: 1 }}
            disabled={!canEdit}
          />

          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (url.trim()) {
                window.open(url.trim(), "_blank");
              }
            }}
            disabled={!url.trim()}
            sx={{ marginLeft: 1 }}
          >
            <OpenInNewIcon />
          </IconButton>

          {canEdit && (
            <IconButton
              onClick={() => handleRemoveBriefUrl(index)}
              sx={{ marginLeft: 1 }}
            >
              <RemoveIcon />
            </IconButton>
          )}
        </div>
      ))}
    </div>
  );
};

export default BriefContainer;
