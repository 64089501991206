import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Typography,
  AppBar,
  Toolbar,
} from "@mui/material";
import { listFolderContents, getThumbnails } from "../DropboxFunctions";
import {
  InstructionsDialog,
  ResetSelectsDialog,
  ConfirmSelectsDialog,
  SelectsMadeDialog,
} from "./DialogComponents";
import SelectedImagesGrid from "./SelectedImagesGrid";
import EnhancedAccordion from "./EnhancedAccordion";
import ResizableLayout from "./ResizableLayout";
import { use100vh } from "react-div-100vh";
import { useTheme } from "@mui/material/styles";

export const sortImagesByFilename = (a, b) => {
  const fileA = a.path_display.split("/").pop();
  const fileB = b.path_display.split("/").pop();
  return fileA.localeCompare(fileB, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

export const clearThumbnails = (res) =>
  res.map((item) => ({ ...item, thumbnailUrl: "" }));

const getFolderNameFromFilename = (filename) => {
  if (!filename.toLowerCase().endsWith(".jpg")) return null;
  const parts = filename.split(/(-\d{5,6}-.+?-\d+\.jpg)$/i);
  if (parts.length >= 2 && parts[0].trim()) {
    return parts[0].trim();
  }
  return null;
};

const splitIntoParts = (array, partSize = 100) => {
  const parts = [];
  for (let i = 0; i < array.length; i += partSize) {
    parts.push(array.slice(i, i + partSize));
  }
  return parts;
};

const organizeImagesIntoFolders = (images, dropboxPath) => {
  const folderStructure = {};
  const sortedImages = [...images].sort(sortImagesByFilename);

  sortedImages.forEach((image) => {
    const fileName = image.path_display.split("/").pop();
    const extractedFolder = getFolderNameFromFilename(fileName);
    if (extractedFolder) {
      if (!folderStructure[extractedFolder]) {
        folderStructure[extractedFolder] = { files: [], subfolders: {} };
      }
      folderStructure[extractedFolder].files.push(image);
      return;
    }

    const pathParts = image.path_display.split("/").filter(Boolean);
    const dropboxParts = dropboxPath.split("/").filter(Boolean);
    const relativePath = pathParts.slice(dropboxParts.length);

    if (relativePath.length <= 1) {
      if (!folderStructure.noFolder) folderStructure.noFolder = [];
      folderStructure.noFolder.push(image);
      return;
    }

    const folderName = relativePath[0];
    if (!folderStructure[folderName]) {
      folderStructure[folderName] = { files: [], subfolders: {} };
    }

    if (relativePath.length === 2) {
      folderStructure[folderName].files.push(image);
    } else {
      const subfolderName = relativePath[1];
      if (!folderStructure[folderName].subfolders[subfolderName]) {
        folderStructure[folderName].subfolders[subfolderName] = [];
      }
      folderStructure[folderName].subfolders[subfolderName].push(image);
    }
  });

  return folderStructure;
};

const ImageGrid = ({ firebase, jobID, attestationID }) => {
  const height = use100vh();
  const theme = useTheme();

  const [selectsMade, setSelectsMade] = useState(false);
  const [confirmSelectsDialogOpen, setConfirmSelectsDialogOpen] =
    useState(false);
  const [passedAttestation, setPassedAttestation] = useState(true);
  const [dropboxPath, setDropboxPath] = useState("");
  const [jobName, setJobName] = useState("");
  const [jobDoc, setJobDoc] = useState("");

  const [selectsAreLocked, setSelectsAreLocked] = useState(false);
  const localStorageKey = "gridColumnsAcross";
  const storedColumns = parseInt(localStorage.getItem(localStorageKey), 10);
  const [gridColumns, setGridColumns] = useState(
    storedColumns && storedColumns >= 1 && storedColumns <= 5
      ? storedColumns
      : 3
  );
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [lockedSelectedImages, setLockedSelectedImages] = useState([]);

  const [hasMore, setHasMore] = useState(true);
  const [cursor, setCursor] = useState(null);
  const [loading, setLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const thumbnailQueue = useRef([]);
  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Dialog state: instructions vs. selects-made dialog
  // const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [instructionsOpen, setInstructionsOpen] = useState(true);
  const [selectsMadeDialogOpen, setSelectsMadeDialogOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [rootFolderTitle, setRootFolderTitle] = useState("Root Folder");

  useEffect(() => {
    localStorage.setItem(localStorageKey, gridColumns);
  }, [gridColumns]);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmSelects = async () => {
    try {
      await firebase
        .firestore()
        .collection("ClientSelects")
        .doc(jobID)
        .update({
          selectedImages: clearThumbnails(selectedImages),
          selectsMade: true,
        });

      const result = await firebase
        .functions()
        .httpsCallable("batchCopyToClientSelects")({
        files: selectedImages,
        basePath: dropboxPath,
        jobDoc: jobDoc,
        jobName: jobName,
      });

      if (result.data.success) {
        console.log("Copy process started");
        setSelectsMade(true);
      } else {
        console.error("Error starting copy process:", result.data.error);
      }
    } catch (error) {
      console.error("Error in confirm selects:", error);
    }
  };

  const handleReset = () => {
    if (selectsMade) return;
    setSelectedImages([]);
    handleCloseDialog();
  };

  const updateImagesWithThumbnails = useCallback((thumbnails, batch) => {
    setImages((prev) => {
      const hasChanges = batch.some(
        (image) =>
          !prev.find((img) => img.path_lower === image.path_lower)
            ?.thumbnailUrl && thumbnails[image.path_lower]
      );
      return hasChanges
        ? prev.map((img) => ({
            ...img,
            thumbnailUrl:
              !img.thumbnailUrl &&
              batch.some((batchImg) => batchImg.path_lower === img.path_lower)
                ? thumbnails[img.path_lower] || null
                : img.thumbnailUrl,
          }))
        : prev;
    });

    setSelectedImages((prev) => {
      const selectedImagesHasChanges = batch.some((image) => {
        const selectedImage = prev.find(
          (img) => img.path_lower === image.path_lower
        );
        return (
          selectedImage &&
          !selectedImage.thumbnailUrl &&
          thumbnails[image.path_lower]
        );
      });

      if (!selectedImagesHasChanges) return prev;

      return prev.map((img) => ({
        ...img,
        thumbnailUrl:
          !img.thumbnailUrl &&
          batch.some((batchImg) => batchImg.path_lower === img.path_lower)
            ? thumbnails[img.path_lower] || null
            : img.thumbnailUrl,
      }));
    });
  }, []);

  const processThumbnailQueue = useCallback(async () => {
    if (thumbnailQueue.current.length === 0) return;

    const firstBatch = thumbnailQueue.current.slice(0, 25);
    const secondBatch = thumbnailQueue.current.slice(25, 50);

    try {
      const firstThumbnails = await getThumbnails(firstBatch);
      updateImagesWithThumbnails(firstThumbnails, firstBatch);

      if (secondBatch.length > 0) {
        const secondThumbnails = await getThumbnails(secondBatch);
        updateImagesWithThumbnails(secondThumbnails, secondBatch);
        thumbnailQueue.current = thumbnailQueue.current.slice(50);
      } else {
        thumbnailQueue.current = thumbnailQueue.current.slice(25);
      }

      if (thumbnailQueue.current.length > 0) {
        processThumbnailQueue();
      }
    } catch (error) {
      console.error("Error loading thumbnails:", error);
    }
  }, [updateImagesWithThumbnails]);

  const handleAccordionExpand = useCallback(
    (images) => {
      thumbnailQueue.current.push(...images);
      processThumbnailQueue();
    },
    [processThumbnailQueue]
  );

  const loadImagesFromPath = async (path, existingCursor = null) => {
    let allEntries = [];
    let currentCursor = existingCursor;
    let hasMoreItems = true;

    while (hasMoreItems) {
      try {
        const result = await listFolderContents(path, currentCursor);
        const entries = result.entries.map((entry) => ({
          ...entry,
          thumbnailUrl: null,
        }));

        allEntries = [...allEntries, ...entries];
        hasMoreItems = result.hasMore;
        currentCursor = result.cursor;

        if (!hasMoreItems) break;
      } catch (error) {
        console.error(`Error loading images from ${path}:`, error);
        hasMoreItems = false;
      }
    }

    return {
      entries: allEntries,
      hasMore: hasMoreItems,
      cursor: currentCursor,
    };
  };

  const loadImages = useCallback(
    async (dropboxPath, selectsAreLocked2) => {
      if (isLoadingRef.current || !hasMore) return;
      isLoadingRef.current = true;
      setLoading(true);

      try {
        let quickProofsResult = await loadImagesFromPath(
          `${dropboxPath}/Quickproofs`,
          cursor
        );
        if (quickProofsResult.entries.length === 0) {
          quickProofsResult = await loadImagesFromPath(
            `${dropboxPath}/Storyboard Selects`,
            cursor
          );
          setRootFolderTitle("Storyboard Selects");
        }
        console.log(quickProofsResult);

        const selectsResult = await loadImagesFromPath(
          `${dropboxPath}/Storyboard Selects`
        );

        const selectsFilenames = new Map(
          selectsResult.entries
            .sort(sortImagesByFilename)
            .map((entry) => [entry.path_display.split("/").pop(), entry])
        );

        const newImages = quickProofsResult.entries.sort(sortImagesByFilename);

        setImages((prev) => [...prev, ...newImages]);

        const newSelectedImages = newImages
          .filter((image) =>
            selectsFilenames.has(image.path_display.split("/").pop())
          )
          .map((image) => ({
            ...image,
            storyboardPath: selectsFilenames.get(
              image.path_display.split("/").pop()
            ).path_lower,
          }));

        if (newSelectedImages.length > 0) {
          if (selectsAreLocked2) {
            setLockedSelectedImages((prev) => {
              if (prev.length > 0) return prev;
              const combined = [...prev, ...newSelectedImages];
              return Array.from(
                new Map(combined.map((img) => [img.path_lower, img])).values()
              ).sort(sortImagesByFilename);
            });
          } else {
            setSelectedImages((prev) => {
              if (prev.length > 0) return prev;
              const combined = [...prev, ...newSelectedImages];
              return Array.from(
                new Map(combined.map((img) => [img.path_lower, img])).values()
              ).sort(sortImagesByFilename);
            });
          }
          thumbnailQueue.current.push(...newSelectedImages);
        }

        const nonSelectedImages = newImages.filter(
          (image) =>
            !newSelectedImages.some(
              (selected) => selected.path_lower === image.path_lower
            )
        );
        thumbnailQueue.current.push(...nonSelectedImages);

        processThumbnailQueue();

        setHasMore(quickProofsResult.hasMore);
        setCursor(quickProofsResult.cursor);
      } catch (error) {
        console.error("Error loading images:", error);
      } finally {
        setLoading(false);
        isLoadingRef.current = false;
      }
    },
    [cursor, hasMore, processThumbnailQueue]
  );

  useEffect(() => {
    let unsubscribe;

    const main = async () => {
      const jobDataRaw = await firebase
        .firestore()
        .collection("JobData")
        .doc(jobID)
        .get();
      const jobDocLoc = jobDataRaw.data();
      setJobDoc(jobDocLoc);
      const jn = `${jobDocLoc.jobNumber}-${jobDocLoc.client}${jobDocLoc.jobTypeShort}`;
      setJobName(jn);
      let dbPath = `/${jobDocLoc.client}/${jn}`;
      if (jobDocLoc.multipleDays) {
        dbPath = `/${jobDocLoc.client}/${jn}/${jobDocLoc.multipleDays}`;
        console.log(jobDocLoc.multipleDays);
      }
      setDropboxPath(dbPath);

      const clientSelectsRaw = await firebase
        .firestore()
        .collection("ClientSelects")
        .doc(jobID)
        .get();
      const clientSelectsDoc = clientSelectsRaw.data();

      if (!clientSelectsRaw.exists) {
        setPassedAttestation(false);
        return;
      }

      if (clientSelectsDoc?.selectsMade) {
        setSelectsMade(true);
        setSelectsMadeDialogOpen(true);
      }
      if (jobDocLoc?.selectsAreLocked) {
        setSelectsAreLocked(true);
      }

      if (clientSelectsDoc.attestationID === attestationID) {
        setPassedAttestation(true);
        if (
          clientSelectsDoc.selectedImages &&
          clientSelectsDoc.selectedImages.length > 0
        ) {
          const updatedSelectedImages = clientSelectsDoc.selectedImages.map(
            (selectedImage) => {
              const selectedFileName = selectedImage.path_display
                .split("/")
                .pop();
              const matchingImage = images.find(
                (img) => img.path_display.split("/").pop() === selectedFileName
              );
              return matchingImage?.thumbnailUrl
                ? {
                    ...selectedImage,
                    thumbnailUrl: matchingImage.thumbnailUrl,
                  }
                : selectedImage;
            }
          );
          thumbnailQueue.current.push(...updatedSelectedImages);
          processThumbnailQueue();
          setSelectedImages(updatedSelectedImages);
        }
        loadImages(dbPath, jobDocLoc?.selectsAreLocked);
      } else {
        setPassedAttestation(false);
      }
    };
    main();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setDimensions({
          width: rect.width,
          height: rect.height,
        });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleImageSelect = useCallback(
    (imageOrImages, isBulkRemove = false) => {
      if (selectsMade) return;
      setSelectedImages((prev) => {
        if (isBulkRemove) {
          const imagesToRemove = Array.isArray(imageOrImages)
            ? imageOrImages
            : [imageOrImages];
          return prev
            .filter(
              (img) =>
                !imagesToRemove.some((toRemove) => toRemove.name === img.name)
            )
            .sort(sortImagesByFilename);
        }

        const image = Array.isArray(imageOrImages)
          ? imageOrImages[0]
          : imageOrImages;
        const isSelected = prev.some((img) => img.name === image.name);

        if (isSelected) {
          const resDS = prev
            .filter((img) => img.name !== image.name)
            .sort(sortImagesByFilename);
          firebase
            .firestore()
            .collection("ClientSelects")
            .doc(jobID)
            .update({ selectedImages: clearThumbnails(resDS) });

          return resDS;
        }

        const res = [...prev, image].sort(sortImagesByFilename);
        firebase
          .firestore()
          .collection("ClientSelects")
          .doc(jobID)
          .update({ selectedImages: clearThumbnails(res) });
        return res;
      });
    },
    [selectsMade, firebase, jobID]
  );

  const itemSize = Math.floor((dimensions.width - 96) / gridColumns);

  const folderStructure = organizeImagesIntoFolders(
    images,
    `${dropboxPath}/Quickproofs`
  );

  return (
    <>
      {passedAttestation ? (
        <>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : undefined,
              zIndex: (theme) => theme.zIndex.drawer + 1,
              padding: "0 !important",
            }}
          >
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  width: 420,
                  alignItems: "center",
                }}
              >
                <img
                  src="https://www.imagesthatsell.com.au/ITS_LOGO_SMALL.png"
                  alt="ITS Logo"
                  style={{
                    height: 50,
                    filter: theme.palette.mode === "dark" ? "invert(0.85)" : "",
                  }}
                />
                <Select
                  value={gridColumns}
                  onChange={(e) => setGridColumns(e.target.value)}
                  sx={{ height: 40, width: 130 }}
                  fullWidth
                >
                  <MenuItem value={1}>1 Across</MenuItem>
                  <MenuItem value={2}>2 Across</MenuItem>
                  <MenuItem value={3}>3 Across</MenuItem>
                  <MenuItem value={4}>4 Across</MenuItem>
                  <MenuItem value={5}>5 Across</MenuItem>
                </Select>
              </Box>

              <Typography
                variant="h6"
                component="div"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === "light"
                      ? "black"
                      : theme.palette.text.primary,
                }}
              >
                {jobName}
              </Typography>

              <Box sx={{ display: "flex", gap: 2, width: 420 }}>
                {!selectsMade ? (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleOpenDialog}
                    sx={{ minWidth: 180 }}
                    disabled={selectsMade}
                  >
                    Reset Selects
                  </Button>
                ) : (
                  <div style={{ minWidth: 180 }}></div>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: 240 }}
                  disabled={selectsMade}
                  onClick={() => setConfirmSelectsDialogOpen(true)}
                >
                  {selectsMade
                    ? `Selects Confirmed (${selectedImages.length})`
                    : `Confirm Selects (${selectedImages.length})`}
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
          <ResizableLayout>
            <div
              style={{
                height: `calc(${height}px - 70px)`,
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                marginTop: "64px",
              }}
            >
              {!selectsMade && (
                <InstructionsDialog
                  open={instructionsOpen}
                  onClose={() => setInstructionsOpen(false)}
                />
              )}
              {selectsMade && (
                <SelectsMadeDialog
                  open={selectsMadeDialogOpen}
                  onClose={() => setSelectsMadeDialogOpen(false)}
                />
              )}
              <ResetSelectsDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onReset={handleReset}
              />
              <ConfirmSelectsDialog
                open={confirmSelectsDialogOpen}
                onClose={() => setConfirmSelectsDialogOpen(false)}
                onConfirm={handleConfirmSelects}
                selectedCount={selectedImages.length}
              />

              <div ref={containerRef} style={{ flex: 1, padding: "8px" }}>
                {dimensions.width > 0 && (
                  <>
                    {folderStructure.noFolder &&
                      (folderStructure.noFolder.length > 100 ? (
                        <EnhancedAccordion
                          title={rootFolderTitle}
                          images={[]}
                          selectedImages={selectedImages}
                          lockedSelectedImages={lockedSelectedImages}
                          onImageSelect={handleImageSelect}
                          gridColumns={gridColumns}
                          dimensions={dimensions}
                          itemSize={itemSize}
                          onExpand={handleAccordionExpand}
                          subfolders={splitIntoParts(
                            folderStructure.noFolder,
                            100
                          ).reduce((acc, part, index) => {
                            acc[`Part ${index + 1}`] = part;
                            return acc;
                          }, {})}
                          selectsMade={selectsMade}
                        />
                      ) : (
                        <EnhancedAccordion
                          title={rootFolderTitle}
                          images={folderStructure.noFolder}
                          selectedImages={selectedImages}
                          lockedSelectedImages={lockedSelectedImages}
                          onImageSelect={handleImageSelect}
                          gridColumns={gridColumns}
                          dimensions={dimensions}
                          itemSize={itemSize}
                          onExpand={handleAccordionExpand}
                          selectsMade={selectsMade}
                        />
                      ))}

                    {/* For other folders */}
                    {Object.entries(folderStructure)
                      .filter(([key]) => key !== "noFolder")
                      .map(([folderName, content]) => (
                        <React.Fragment key={folderName}>
                          {content.files && content.files.length > 100 ? (
                            <EnhancedAccordion
                              key={folderName}
                              title={folderName}
                              images={[]}
                              selectedImages={selectedImages}
                              lockedSelectedImages={lockedSelectedImages}
                              onImageSelect={handleImageSelect}
                              gridColumns={gridColumns}
                              dimensions={dimensions}
                              itemSize={itemSize}
                              onExpand={handleAccordionExpand}
                              subfolders={{
                                ...content.subfolders,
                                ...splitIntoParts(content.files, 100).reduce(
                                  (acc, part, index) => {
                                    acc[`Part ${index + 1}`] = part;
                                    return acc;
                                  },
                                  {}
                                ),
                              }}
                              selectsMade={selectsMade}
                            />
                          ) : (
                            <EnhancedAccordion
                              key={folderName}
                              title={folderName}
                              images={content.files}
                              selectedImages={selectedImages}
                              lockedSelectedImages={lockedSelectedImages}
                              onImageSelect={handleImageSelect}
                              gridColumns={gridColumns}
                              dimensions={dimensions}
                              itemSize={itemSize}
                              onExpand={handleAccordionExpand}
                              subfolders={content.subfolders}
                              selectsMade={selectsMade}
                            />
                          )}
                        </React.Fragment>
                      ))}
                  </>
                )}
                {loading && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      color: "#fff",
                    }}
                  >
                    Loading Images...
                  </div>
                )}
              </div>
            </div>
            <SelectedImagesGrid
              selectedImages={selectedImages}
              lockedSelectedImages={lockedSelectedImages}
              jobName={jobName}
            />
          </ResizableLayout>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            color: "#fff",
          }}
        >
          Something went wrong... please click the link in the email again.
        </div>
      )}
    </>
  );
};

export default ImageGrid;
