import React, { useState, useEffect, useRef, useMemo } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { use100vh } from "react-div-100vh";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Menu as ContexifyMenu,
  Item,
  Submenu,
  useContextMenu,
} from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import jsPDF from "jspdf";

const menuStyles = `
.react-contexify__menu {
  background-color: #fff !important;
  border: 1px solid #ccc !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2) !important;
  padding: 4px 0 !important;
}
.react-contexify__item {
  padding: 4px 12px !important;
  cursor: pointer !important;
}
.react-contexify__item:hover {
  background-color: #eee !important;
}
.react-contexify__submenu-arrow {
  float: right !important;
}
`;
const styleTag = document.createElement("style");
styleTag.type = "text/css";
styleTag.appendChild(document.createTextNode(menuStyles));
document.head.appendChild(styleTag);

const ThumbnailManager = React.memo(({ selectedImages }) => {
  const loadedPaths = useRef(new Set());
  useEffect(() => {
    const preloadImages = async () => {
      const newImages = selectedImages.filter(
        (img) => img.thumbnailUrl && !loadedPaths.current.has(img.path_lower)
      );
      if (newImages.length === 0) return;
      await Promise.all(
        newImages.map(
          (image) =>
            new Promise((resolve) => {
              const img = new Image();
              img.onload = () => {
                loadedPaths.current.add(image.path_lower);
                resolve();
              };
              img.onerror = resolve;
              img.src = `data:image/jpeg;base64,${image.thumbnailUrl}`;
            })
        )
      );
    };
    preloadImages();
  }, [selectedImages]);
  return null;
});

const ZoomableCanvas = ({
  canvasRef,
  isZoomed,
  setIsZoomed,
  zoomOrigin,
  setZoomOrigin,
}) => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [dragged, setDragged] = useState(false);
  const dragSpeedFactor = 0.5;
  const [loupeDiameterSetting, setLoupeDiameterSetting] = useState(350);
  const [loupeMagnificationSetting, setLoupeMagnificationSetting] =
    useState(1.5);
  const [loupeVisible, setLoupeVisible] = useState(false);
  const [loupePos, setLoupePos] = useState({ x: 0, y: 0 });
  const loupeCanvasRef = useRef(null);
  const { show } = useContextMenu({ id: "canvas_menu" });

  useEffect(() => {
    if (!isZoomed) {
      setDragOffset({ x: 0, y: 0 });
    }
  }, [isZoomed]);

  const handleStart = (clientX, clientY) => {
    if (isZoomed) {
      setIsDragging(true);
      setDragged(false);
      setDragStart({
        x: clientX - dragOffset.x / dragSpeedFactor,
        y: clientY - dragOffset.y / dragSpeedFactor,
      });
    }
  };

  const handleMove = (clientX, clientY) => {
    if (isZoomed && isDragging) {
      const newX = (clientX - dragStart.x) * dragSpeedFactor;
      const newY = (clientY - dragStart.y) * dragSpeedFactor;
      setDragOffset({ x: newX, y: newY });
      setDragged(true);
    }
    if (!isZoomed) {
      updateLoupe(clientX, clientY);
    }
  };

  const handleEnd = () => {
    setIsDragging(false);
  };

  const updateLoupe = (clientX, clientY) => {
    if (!canvasRef.current || !loupeCanvasRef.current) return;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = clientX - rect.left;
    const y = clientY - rect.top;
    setLoupePos({ x, y });
    const scaleX = canvasRef.current.width / rect.width;
    const scaleY = canvasRef.current.height / rect.height;
    const canvasX = x * scaleX;
    const canvasY = y * scaleY;
    const sampleWidth = loupeDiameterSetting / loupeMagnificationSetting;
    const sampleHeight = loupeDiameterSetting / loupeMagnificationSetting;
    const sx = canvasX - sampleWidth / 2;
    const sy = canvasY - sampleHeight / 2;
    const loupeCtx = loupeCanvasRef.current.getContext("2d");
    if (!loupeCtx) return;
    loupeCtx.clearRect(0, 0, loupeDiameterSetting, loupeDiameterSetting);
    loupeCtx.imageSmoothingEnabled = true;
    loupeCtx.imageSmoothingQuality = "high";
    loupeCtx.drawImage(
      canvasRef.current,
      sx,
      sy,
      sampleWidth,
      sampleHeight,
      0,
      0,
      loupeDiameterSetting,
      loupeDiameterSetting
    );
  };

  const handleMouseDown = (e) => handleStart(e.clientX, e.clientY);
  const handleMouseMove = (e) => {
    handleMove(e.clientX, e.clientY);
    if (!isZoomed) {
      updateLoupe(e.clientX, e.clientY);
    }
  };
  const handleMouseUp = (e) => handleEnd();
  const handleMouseEnter = (e) => {
    if (!isZoomed) {
      setLoupeVisible(true);
      updateLoupe(e.clientX, e.clientY);
    }
  };
  const handleContainerMouseLeave = (e) => {
    setLoupeVisible(false);
    handleMouseUp(e);
  };
  const handleTouchStart = (e) => {
    if (e.touches.length === 1) {
      e.preventDefault();
      handleStart(e.touches[0].clientX, e.touches[0].clientY);
    }
  };
  const handleTouchMove = (e) => {
    if (e.touches.length === 1) {
      e.preventDefault();
      handleMove(e.touches[0].clientX, e.touches[0].clientY);
    }
  };
  const handleTouchEnd = handleEnd;

  const handleClick = (e) => {
    if (!dragged) {
      if (!isZoomed) {
        const rect = containerRef.current.getBoundingClientRect();
        setZoomOrigin({
          x: (e.clientX - rect.left) / rect.width,
          y: (e.clientY - rect.top) / rect.height,
        });
      }
      setIsZoomed(!isZoomed);
      if (!isZoomed) {
        updateLoupe(e.clientX, e.clientY);
      }
    }
  };

  const handleContextMenu = (e) => {
    if (!e) return;
    e.preventDefault();
    show({ event: e.nativeEvent || e });
  };

  const canvasStyle = {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: isZoomed ? "grab" : "zoom-in",
    transform: isZoomed
      ? `scale(2.3) translate(${dragOffset.x}px, ${dragOffset.y}px)`
      : "none",
    transformOrigin: isZoomed
      ? `${zoomOrigin.x * 100}% ${zoomOrigin.y * 100}%`
      : "center",
    transition: isDragging ? "none" : "transform 0.3s ease-out",
  };

  return (
    <>
      <div
        ref={containerRef}
        style={{
          width: "100%",
          height: "calc(100% - 80px)",
          overflow: "hidden",
          position: "relative",
        }}
        onContextMenu={handleContextMenu}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleContainerMouseLeave}
        // onClick={handleClick}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <canvas ref={canvasRef} style={canvasStyle} />
        {!isZoomed && loupeVisible && (
          <canvas
            ref={loupeCanvasRef}
            width={loupeDiameterSetting}
            height={loupeDiameterSetting}
            style={{
              position: "absolute",
              width: `${loupeDiameterSetting}px`,
              height: `${loupeDiameterSetting}px`,
              borderRadius: "50%",
              border: "2px solid #ccc",
              top: `${loupePos.y - loupeDiameterSetting / 2}px`,
              left: `${loupePos.x - loupeDiameterSetting / 2}px`,
              pointerEvents: "none",
              boxShadow: "0 0 8px rgba(0,0,0,0.3)",
            }}
          />
        )}
      </div>

      <ContexifyMenu id="canvas_menu">
        <Submenu label="Loupe Size">
          <Item onClick={() => setLoupeDiameterSetting(150)}>Small</Item>
          <Item onClick={() => setLoupeDiameterSetting(250)}>Medium</Item>
          <Item onClick={() => setLoupeDiameterSetting(350)}>Large</Item>
          <Item onClick={() => setLoupeDiameterSetting(500)}>Extra Large</Item>
        </Submenu>
        <Submenu label="Loupe Zoom">
          <Item onClick={() => setLoupeMagnificationSetting(0.6)}>50%</Item>
          <Item onClick={() => setLoupeMagnificationSetting(1)}>100%</Item>
          <Item onClick={() => setLoupeMagnificationSetting(1.5)}>150%</Item>
          <Item onClick={() => setLoupeMagnificationSetting(2)}>200%</Item>
          <Item onClick={() => setLoupeMagnificationSetting(3)}>300%</Item>
        </Submenu>
      </ContexifyMenu>
    </>
  );
};

const SelectedImagesGrid = ({ selectedImages, jobName }) => {
  const canvasRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageBreaks, setPageBreaks] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomOrigin, setZoomOrigin] = useState({ x: 0.5, y: 0.5 });
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);

  const pageData = useMemo(() => {
    const cols = 6;
    const rows = 6;
    const maxSlotsPerPage = cols * rows;
    let currentSlots = 0;
    let breaks = [];
    for (let i = 0; i < selectedImages.length; i++) {
      const slotsNeeded = 1;
      if (currentSlots + slotsNeeded > maxSlotsPerPage) {
        breaks.push(i);
        currentSlots = slotsNeeded;
      } else {
        currentSlots += slotsNeeded;
      }
    }
    if (
      breaks.length === 0 ||
      breaks[breaks.length - 1] !== selectedImages.length
    ) {
      breaks.push(selectedImages.length);
    }
    return breaks;
  }, [selectedImages]);

  useEffect(() => {
    setPageBreaks(pageData);
  }, [pageData]);

  const totalPages = Math.max(1, pageBreaks.length);

  useEffect(() => {
    if (currentPage >= totalPages) {
      setCurrentPage(Math.max(0, totalPages - 1));
    }
  }, [totalPages, currentPage]);

  useEffect(() => {
    (async () => {
      await renderCanvasForPage(currentPage);
    })();
  }, [currentPage, pageBreaks]);

  const renderCanvasForPage = async (page) => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";

    canvas.width = 4200;
    canvas.height = 6000;

    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const cols = 6;
    const rows = 6;
    const padding = 90;
    const outerPadding = 100;
    const availableWidth =
      canvas.width - 2 * outerPadding - padding * (cols - 1);
    const cellWidth = availableWidth / cols;
    const cellHeight = cellWidth * 1.5;

    const startIdx = page === 0 ? 0 : pageBreaks[page - 1];
    const endIdx = pageBreaks[page];
    const pageImages = selectedImages.slice(startIdx, endIdx);

    const occupiedCells = new Set();
    let maxRow = -1;

    const findNextPosition = (isWide) => {
      for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
          if (!occupiedCells.has(`${row},${col}`)) {
            if (!isWide) return { row, col };
            if (col < cols - 1 && !occupiedCells.has(`${row},${col + 1}`)) {
              return { row, col };
            }
          }
        }
      }
      return null;
    };

    for (const image of pageImages) {
      if (image.thumbnailUrl) {
        const img = await new Promise((resolve) => {
          const imgEl = new Image();
          imgEl.onload = () => resolve(imgEl);
          imgEl.onerror = resolve;
          imgEl.src = `data:image/jpeg;base64,${image.thumbnailUrl}`;
        });
        if (!img) continue;

        const imgAspect = img.width / img.height;
        const isWide = imgAspect > 1;
        const position = findNextPosition(isWide);
        if (!position) continue;

        const { row, col } = position;
        maxRow = Math.max(maxRow, row);

        if (isWide && col < cols - 1) {
          occupiedCells.add(`${row},${col}`);
          occupiedCells.add(`${row},${col + 1}`);

          const x = outerPadding + col * (cellWidth + padding);
          const y = outerPadding + row * (cellHeight + padding);
          const doubleCellWidth = cellWidth * 2 + padding;

          let drawWidth = doubleCellWidth;
          let drawHeight = drawWidth / imgAspect;

          if (drawHeight > cellHeight) {
            drawHeight = cellHeight;
            drawWidth = drawHeight * imgAspect;
          }

          const drawX = x + (doubleCellWidth - drawWidth) / 2;
          const drawY = y + (cellHeight - drawHeight) / 2;

          ctx.drawImage(img, drawX, drawY, drawWidth, drawHeight);
        } else {
          occupiedCells.add(`${row},${col}`);

          const x = outerPadding + col * (cellWidth + padding);
          const y = outerPadding + row * (cellHeight + padding);
          const cellAspect = cellWidth / cellHeight;

          let drawWidth = cellWidth;
          let drawHeight = cellHeight;
          let drawX = x;
          let drawY = y;

          if (imgAspect > cellAspect) {
            drawHeight = drawWidth / imgAspect;
            drawY += (cellHeight - drawHeight) / 2;
          } else {
            drawWidth = drawHeight * imgAspect;
            drawX += (cellWidth - drawWidth) / 2;
          }

          ctx.drawImage(img, drawX, drawY, drawWidth, drawHeight);
        }
      } else {
        const position = findNextPosition(false);
        if (!position) continue;

        const { row, col } = position;
        maxRow = Math.max(maxRow, row);

        const x = outerPadding + col * (cellWidth + padding);
        const y = outerPadding + row * (cellHeight + padding);

        occupiedCells.add(`${row},${col}`);
        ctx.fillStyle = "#333";
        ctx.fillRect(x, y, cellWidth, cellHeight);
      }
    }

    return { maxRow, outerPadding, padding, cellHeight };
  };

  const downloadJPEGs = async () => {
    if (!canvasRef.current) return;
    setIsDownloading(true);
    const originalPage = currentPage;
    const link = document.createElement("a");

    try {
      for (let page = 0; page < totalPages; page++) {
        const result = await renderCanvasForPage(page);
        await new Promise((resolve) => requestAnimationFrame(resolve));

        let cropHeight = canvasRef.current.height;
        if (
          page === totalPages - 1 &&
          result &&
          typeof result.maxRow === "number" &&
          result.maxRow >= 0
        ) {
          const computedHeight =
            result.outerPadding +
            result.maxRow * (result.cellHeight + result.padding) +
            result.cellHeight +
            50;
          cropHeight = Math.min(computedHeight, canvasRef.current.height);
        }

        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = canvasRef.current.width;
        tempCanvas.height = cropHeight;
        const tempCtx = tempCanvas.getContext("2d");
        tempCtx.drawImage(
          canvasRef.current,
          0,
          0,
          canvasRef.current.width,
          cropHeight,
          0,
          0,
          canvasRef.current.width,
          cropHeight
        );

        const blob = await new Promise((resolve) => {
          tempCanvas.toBlob(resolve, "image/jpeg", 0.95);
        });

        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = `${jobName}-Storyboard-${page + 1}.jpg`;
        link.click();
        URL.revokeObjectURL(url);
        await new Promise((r) => setTimeout(r, 200));
      }
    } catch (error) {
      console.error("Error downloading JPEGs:", error);
    } finally {
      setIsDownloading(false);
      await renderCanvasForPage(originalPage);
    }
  };

  const downloadPDF = async () => {
    if (!canvasRef.current) return;
    setIsDownloading(true);
    const originalPage = currentPage;
    let pdf;

    try {
      for (let page = 0; page < totalPages; page++) {
        await renderCanvasForPage(page);
        await new Promise((resolve) => requestAnimationFrame(resolve));

        const cropHeight = canvasRef.current.height;

        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = canvasRef.current.width;
        tempCanvas.height = cropHeight;
        const tempCtx = tempCanvas.getContext("2d");
        tempCtx.drawImage(
          canvasRef.current,
          0,
          0,
          canvasRef.current.width,
          cropHeight,
          0,
          0,
          canvasRef.current.width,
          cropHeight
        );

        const imgData = tempCanvas.toDataURL("image/jpeg", 0.95);
        const pageWidth = tempCanvas.width;
        const pageHeight = tempCanvas.height;

        if (page === 0) {
          pdf = new jsPDF({
            unit: "px",
            format: [pageWidth, pageHeight],
          });
          pdf.addImage(imgData, "JPEG", 0, 0, pageWidth, pageHeight);
        } else {
          pdf.addPage([pageWidth, pageHeight]);
          pdf.addImage(imgData, "JPEG", 0, 0, pageWidth, pageHeight);
        }
      }

      const pdfBlob = pdf.output("blob");
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `${jobName}-Storyboard.pdf`;
      link.click();
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setIsDownloading(false);
      await renderCanvasForPage(originalPage);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        padding: "10px",
        backgroundColor: "#f0f0f0",
        height: `calc(${use100vh()}px - 70px)`,
        position: "relative",
        marginTop: "64px",
      }}
    >
      <ThumbnailManager selectedImages={selectedImages} />
      <ZoomableCanvas
        canvasRef={canvasRef}
        isZoomed={isZoomed}
        setIsZoomed={setIsZoomed}
        zoomOrigin={zoomOrigin}
        setZoomOrigin={setZoomOrigin}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          padding: "10px",
          backgroundColor: "#ffffff",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          position: "absolute",
          bottom: "24px",
          left: "50%",
          transform: "translateX(-50%)",
          minHeight: "48px",
          maxHeight: "48px",
          width: "auto",
          maxWidth: "calc(100% - 48px)",
          overflow: "hidden",
        }}
      >
        <Button
          variant="contained"
          onClick={() => setCurrentPage((p) => Math.max(0, p - 1))}
          disabled={currentPage === 0}
          startIcon={<NavigateBeforeIcon />}
          sx={{
            bgcolor: "#4CAF50",
            "&:hover": { bgcolor: "#45a049" },
            "&:disabled": { bgcolor: "#f0f0f0" },
            minWidth: "85px",
            maxWidth: "85px",
            padding: "6px 12px",
            height: "36px",
          }}
        >
          Prev
        </Button>
        <span
          style={{
            fontWeight: 500,
            color: "#000",
            whiteSpace: "nowrap",
            minWidth: "60px",
            textAlign: "center",
          }}
        >
          {currentPage + 1} of {totalPages}
        </span>
        <Button
          variant="contained"
          onClick={() => setCurrentPage((p) => Math.min(totalPages - 1, p + 1))}
          disabled={currentPage === totalPages - 1}
          endIcon={<NavigateNextIcon />}
          sx={{
            bgcolor: "#4CAF50",
            "&:hover": { bgcolor: "#45a049" },
            "&:disabled": { bgcolor: "#f0f0f0" },
            minWidth: "85px",
            maxWidth: "85px",
            padding: "6px 12px",
            height: "36px",
          }}
        >
          Next
        </Button>
        <Button
          variant="contained"
          onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
          disabled={selectedImages.length === 0 || isDownloading}
          style={{
            backgroundColor: "#2196F3",
            marginLeft: "15px",
            minWidth: "160px",
            maxWidth: "160px",
            padding: "6px 12px",
            height: "36px",
          }}
        >
          {isDownloading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Download"
          )}
        </Button>
        <Menu
          anchorEl={downloadAnchorEl}
          open={Boolean(downloadAnchorEl)}
          onClose={() => setDownloadAnchorEl(null)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <MenuItem
            onClick={async () => {
              setDownloadAnchorEl(null);
              await downloadJPEGs();
            }}
          >
            Download JPEGs
          </MenuItem>
          <MenuItem
            onClick={async () => {
              setDownloadAnchorEl(null);
              await downloadPDF();
            }}
          >
            Download PDF
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default React.memo(SelectedImagesGrid);
