import React from "react";
import {
  Tailwind,
  Html,
  Head,
  Body,
  Container,
  Img,
  Heading,
  Text,
  Section,
  Button,
  Hr,
  Preview,
} from "@react-email/components";

const SupplyEmail = ({
  jobData,
  links,
  subject,
  editMode,
  customText,
  setCustomText,
}) => {
  const noSelect = {
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    khtmlUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  };
  return (
    <Html>
      <Head />
      <Preview>Your files are complete and ready for download.</Preview>
      <Tailwind>
        <Body className="bg-gray-100 font-sans">
          <Container className="max-w-[540px] mx-auto bg-white p-10 rounded-xl shadow-md text-center my-10 drop-shadow-2xl">
            <Section>
              <Section className="mb-[40px]">
                <Img
                  src="https://www.imagesthatsell.com.au/ITS_LOGO_SMALL.png"
                  alt="ITS Logo"
                  width="120"
                  className="mx-auto mb-[10px] mt-[40px]"
                />
              </Section>

              <Heading
                className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase select-none"
                style={noSelect}
              >
                THANK YOU SO MUCH
              </Heading>
              <Text
                className="text-[18px] text-gray-600 mb-[50px] text-transform: uppercase"
                style={noSelect}
              >
                WE REALLY APPRECIATE YOUR BUSINESS
              </Text>

              <Section className="bg-gray-100 rounded-lg mb-[50px] w-[95%]">
                <Text className="text-gray-700 text-[14px]" style={noSelect}>
                  Your latest job is complete:
                </Text>
                <Heading
                  className="text-[18px] pb-5 font-semibold text-gray-800"
                  style={noSelect}
                >
                  {subject}
                </Heading>
              </Section>

              <Text
                className="text-gray-700 text-base leading-relaxed mb-[16px] mt-[20px] px-[4px] whitespace-pre-wrap"
                contentEditable={editMode}
                id="customTextElement"
              >
                {customText}
              </Text>

              <Section
                className="flex flex-col items-center mt-[50px] mb-[55px]"
                style={noSelect}
              >
                {links.map((link, index) => (
                  <Button
                    key={index}
                    href={link.url}
                    className="block bg-black text-white font-bold py-[12px] px-[32px] rounded-lg text-md hover:bg-gray-800 uppercase mb-[10px]"
                    style={noSelect}
                    target="_blank"
                  >
                    {link.label}
                  </Button>
                ))}
              </Section>

              <Hr
                className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent my-[32px]"
                style={noSelect}
              />

              <Text
                className="text-gray-600 text-sm leading-loose px-[4px]"
                style={noSelect}
              >
                Let us know if you have any trouble locating the files or if you
                have any questions.
                <br />
                <br />
                Thank you for working with ITS
              </Text>
            </Section>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};

export default SupplyEmail;
