import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import MarkdownNotesEditorClient from "../../../ClientDatabase/MarkdownNotesEditorClient";

const Notes = ({ client, firebase, updateFS, canEdit, theme, modalWidth }) => {
  const [invoicingNotes, setInvoicingNotes] = useState(client?.invoicingNotes);

  useEffect(() => {
    setInvoicingNotes(client?.invoicingNotes);
  }, [client]);
  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          width: "100%",
          maxWidth: 860,
        }}
      >
        <div style={{ marginBottom: "40px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between",
            }}
          >
            <MarkdownNotesEditorClient
              label="Invoicing Notes"
              clientValue={invoicingNotes}
              setClientValue={(e) => {
                if (!canEdit) return;
                setInvoicingNotes(e);
                updateFS(client?.id, "invoicingNotes", e);
              }}
              theme={theme}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notes;
