// import React, { useState, useEffect, useCallback } from "react";
// import TextField from "@mui/material/TextField";
// import IconButton from "@mui/material/IconButton";
// import RemoveIcon from "@mui/icons-material/Remove";
// import CircularProgress from "@mui/material/CircularProgress";
// import InputAdornment from "@mui/material/InputAdornment";
// import { useAtom } from "jotai";
// import debounce from 'lodash.debounce';
// import { isDebouncingAtom } from "../../../../-Atoms";

// const CateringItem = ({
//   item,
//   items,
//   setItems,
//   index,
//   showRemoveCateringItem,
//   firebase,
//   jobData,
// }) => {
//   const [amountFocus, setAmountFocus] = useState(false);
//   const [storeFocus, setStoreFocus] = useState(false);
//   const [amount, setAmount] = useState("");
//   const [store, setStore] = useState("");
//   const [isTyping, setIsTyping] = useState(false);
//   const [queuedUpdates, setQueuedUpdates] = useState({});
//   const [isDebouncing, setIsDebouncing] = useAtom(isDebouncingAtom);

//   useEffect(() => {
//     if (amount !== item.amount) setAmount(item.amount);
//     if (store !== item.store) setStore(item.store);
//   }, [item, amount, store]);

//   const debouncedUpdateFS = useCallback(
//     debounce((updates) => {
//       setIsDebouncing(true);
//       const batch = firebase.firestore().batch();
//       const docRef = firebase.firestore().collection("JobData").doc(jobData.id);
//       batch.update(docRef, updates);
//       batch.commit().then(() => {
//         setIsDebouncing(false);
//         setIsTyping(false);
//       }).catch((e) => {
//         console.log(e);
//         setIsDebouncing(false);
//       });
//     }, 500),
//     [firebase, jobData.id, setIsDebouncing]
//   );

//   useEffect(() => {
//     if (Object.keys(queuedUpdates).length > 0) {
//       debouncedUpdateFS(queuedUpdates);
//       setQueuedUpdates({});
//     }
//   }, [queuedUpdates, debouncedUpdateFS]);

//   const handleChange = (field, value, setValue) => {
//     setValue(value);
//     setIsTyping(true);
//     item[field] = value;

//     const updatedItems = [...items];
//     updatedItems[index] = item;

//     setItems(updatedItems);
//     setQueuedUpdates((prevUpdates) => ({
//       ...prevUpdates,
//       catering: updatedItems,
//     }));
//   };

//   const removeCateringItem = () => {
//     items.splice(index, 1);
//     setItems([...items]);
//     setQueuedUpdates((prevUpdates) => ({
//       ...prevUpdates,
//       catering: items,
//     }));
//   };

//   return (
//     (<div
//                             style={{
//                               display: "flex",
//                               justifyContent: "space-between",
//                               alignItems: "center",
//                               gap: 15,
//                               width: "100%",
//                             }}
//                           >
//       <TextField
//         inputProps={{ style: { fontSize: 15 } }}
//         InputLabelProps={{ style: { fontSize: 15 } }}
//         label="Amount"
//         variant="filled"
//         value={amount}
//         size="small"
//         sx={{ width: "49%" }}
//         InputProps={{
//           disableUnderline: true,
//           endAdornment: (isTyping || isDebouncing) ? (
//             <InputAdornment position="end">
//               <CircularProgress size={20} />
//             </InputAdornment>
//           ) : null,
//         }}
//         onChange={(e) => {
//           const numericValue = e.target.value.replace(/[^0-9.]/g, "");
//           handleChange("amount", numericValue, setAmount);
//         }}
//         onFocus={() => setAmountFocus(true)}
//         onBlur={() => setTimeout(() => setAmountFocus(false), 300)}
//       />
//       <TextField
//         inputProps={{ style: { fontSize: 15 } }}
//         InputLabelProps={{ style: { fontSize: 15 } }}
//         label="Store"
//         variant="filled"
//         value={store}
//         size="small"
//         sx={{ width: "49%" }}
//         InputProps={{
//           disableUnderline: true,
//           endAdornment: (isTyping || isDebouncing) ? (
//             <InputAdornment position="end">
//               <CircularProgress size={20} />
//             </InputAdornment>
//           ) : null,
//         }}
//         onChange={(e) => handleChange("store", e.target.value, setStore)}
//         onFocus={() => setStoreFocus(true)}
//         onBlur={() => setTimeout(() => setStoreFocus(false), 300)}
//       />
//       <IconButton
//         sx={{ display: showRemoveCateringItem ? "block" : "none" }}
//         onClick={removeCateringItem}
//       >
//         <RemoveIcon />
//       </IconButton>
//     </div>)
//   );
// };

// export default CateringItem;

import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { useAtom } from "jotai";
import debounce from "lodash.debounce";
import { isDebouncingAtom } from "../../../../-Atoms";

const CateringItem = ({
  item,
  items,
  setItems,
  index,
  showRemoveCateringItem,
  firebase,
  jobData,
}) => {
  const [localAmount, setLocalAmount] = useState(item.amount);
  const [localStore, setLocalStore] = useState(item.store);
  const [isTyping, setIsTyping] = useState(false);
  const [isDebouncing, setIsDebouncing] = useAtom(isDebouncingAtom);

  useEffect(() => {
    setLocalAmount(item.amount);
    setLocalStore(item.store);
  }, [item.amount, item.store]);

  const updateFS = useCallback(
    (updatedItems) => {
      setIsDebouncing(true);
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ catering: updatedItems })
        .then(() => {
          // Hide spinner immediately after update
          setIsDebouncing(false);
          setIsTyping(false);
        })
        .catch((e) => {
          console.log(e);
          setIsDebouncing(false);
          setIsTyping(false);
        });
    },
    [firebase, jobData.id, setIsDebouncing]
  );

  const debouncedUpdateFS = useCallback(
    debounce(updateFS, 750), // Changed to 750ms
    [updateFS]
  );

  const handleChange = (field, value) => {
    if (field === "amount") {
      setLocalAmount(value);
    } else {
      setLocalStore(value);
    }
    setIsTyping(true);

    const updatedItems = items.map((i, idx) =>
      idx === index ? { ...i, [field]: value } : i
    );
    setItems(updatedItems);
    debouncedUpdateFS(updatedItems);
  };

  const removeCateringItem = () => {
    const updatedItems = items.filter((_, idx) => idx !== index);
    setItems(updatedItems);
    updateFS(updatedItems);
  };

  return (
    <div className="flex justify-between items-center gap-4 w-full">
      <TextField
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        label="Store"
        variant="filled"
        value={localStore}
        size="small"
        sx={{ width: "49%" }}
        InputProps={{
          disableUnderline: true,
          endAdornment:
            isTyping || isDebouncing ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : null,
        }}
        onChange={(e) => handleChange("store", e.target.value)}
      />
      <TextField
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        label="Amount"
        variant="filled"
        value={localAmount}
        size="small"
        sx={{ width: "49%" }}
        InputProps={{
          disableUnderline: true,
          endAdornment:
            isTyping || isDebouncing ? (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            ) : null,
        }}
        onChange={(e) => {
          const numericValue = e.target.value.replace(/[^0-9.]/g, "");
          handleChange("amount", numericValue);
        }}
      />
      <IconButton
        sx={{ display: showRemoveCateringItem ? "block" : "none" }}
        onClick={removeCateringItem}
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
};

export default CateringItem;
