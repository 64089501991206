import React from "react";
import {
  Tailwind,
  Html,
  Head,
  Body,
  Container,
  Img,
  Heading,
  Text,
  Section,
  Button,
  Hr,
  Preview,
} from "@react-email/components";
import { Markdown } from "@react-email/markdown";

const CODEmail = ({
  jobData,
  links,
  subject,
  editMode,
  customText,
  setCustomText,
}) => {
  const markdownCustomStyles = {
    h1: {
      fontWeight: 700,
      fontSize: "28px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    h2: {
      fontWeight: 700,
      fontSize: "24px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    h3: {
      fontWeight: 700,
      fontSize: "18px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    h4: {
      fontWeight: 700,
      fontSize: "14px",
      marginTop: "2px",
      marginBottom: "2px",
    },
  };
  const noSelect = {
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    khtmlUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
  };
  return (
    <Html>
      <Head />
      <Preview>Please send through remittance for immediate supply.</Preview>
      <Tailwind>
        <Body className="bg-gray-100 font-sans">
          <Container className="max-w-[540px] mx-auto bg-white p-10 rounded-xl shadow-md text-center my-10 drop-shadow-1xl">
            <Section>
              <Section className="mb-[40px]">
                <Img
                  src="https://www.imagesthatsell.com.au/ITS_LOGO_SMALL.png"
                  alt="ITS Logo"
                  width="120"
                  className="mx-auto mb-[10px] mt-[40px]"
                />
              </Section>

              <Heading
                className="text-[24px] font-extrabold text-gray-900 text-transform: uppercase mb-[50px]"
                style={noSelect}
              >
                YOUR FILES ARE READY!
              </Heading>

              <Section className="bg-gray-100 rounded-lg mb-[50px] w-[95%]">
                <Text className="text-gray-700 text-[14px]" style={noSelect}>
                  Your latest job is complete:
                </Text>
                <Heading
                  className="text-[18px] pb-5 font-semibold text-gray-800"
                  style={noSelect}
                >
                  {subject}
                </Heading>
              </Section>

              <Markdown
                className="text-gray-700 text-sm leading-relaxed mb-[16px] mt-[20px] px-[4px] whitespace-pre-wrap"
                contentEditable={editMode}
                id="customTextElement"
                markdownCustomStyles={markdownCustomStyles}
              >
                {customText
                  .replace(
                    /(<colou?r (#[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})>)(.*?)(<\/colou?r>)/gm,
                    `<span style="color: $2">$3</span>`
                  )
                  .replace(
                    /(\*\*|__)(.*?)(\*?)\1/gm,
                    `<span style="font-weight: bold;">$2</span>`
                  )
                  .replace(
                    /(\*|_)(.*?)(\*?)\1/gm,
                    `<span style="font-style: italic;">$2</span>`
                  )}
              </Markdown>

              {/* <Section
                className="flex flex-col items-center mt-[50px] mb-[55px]"
                style={noSelect}
              >
                <Button
                  href={"link.url"}
                  className="block bg-black text-white font-bold py-[12px] px-[32px] rounded-lg text-md hover:bg-gray-800 uppercase mb-[10px]"
                  style={noSelect}
                >
                  {"link.label"}
                </Button>
              </Section> */}

              <Hr
                className="h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent my-[32px]"
                style={noSelect}
              />

              <Text
                className="text-gray-600 text-sm leading-loose px-[4px]"
                style={noSelect}
              >
                Thank you for working with ITS
              </Text>
            </Section>
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};

export default CODEmail;
