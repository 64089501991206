import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Button,
} from "@mui/material";
import { saveAs } from "file-saver";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
const FileSizes = ({
  client,
  firebase,
  updateFS,
  canEdit,
  theme,
  filter = false,
}) => {
  const [lifeFileSizes, setLifeFileSizes] = useState(client?.fileSizes?.life);
  const [productFileSizes, setProductFileSizes] = useState(
    client?.fileSizes?.product
  );
  const [videoFileSizes, setVideoFileSizes] = useState(
    client?.fileSizes?.video
  );

  useEffect(() => {
    setLifeFileSizes(client?.fileSizes?.life);
    setProductFileSizes(client?.fileSizes?.product);
    setVideoFileSizes(client?.fileSizes?.video);
  }, [client]);

  const style = {
    overflow: "auto",
    overflowX: "hidden",
    marginTop: 20,
  };

  const categories = [];

  if (!filter || filter.toLowerCase() === "life") {
    categories.push(
      <FileSizeCategory
        key="FS-CAT-LIFE"
        kind="Life"
        fileSizes={lifeFileSizes}
        firebase={firebase}
        client={client}
        topMargin={categories.length > 0}
        canEdit={canEdit}
        updateFS={updateFS}
        theme={theme}
      />
    );
  }

  if (!filter || filter.toLowerCase() === "product") {
    categories.push(
      <FileSizeCategory
        key="FS-CAT-PRODUCT"
        kind="Product"
        fileSizes={productFileSizes}
        firebase={firebase}
        client={client}
        topMargin={categories.length > 0}
        canEdit={canEdit}
        updateFS={updateFS}
        theme={theme}
      />
    );
  }

  if (!filter || filter.toLowerCase() === "video") {
    categories.push(
      <FileSizeCategory
        key="FS-CAT-VIDEO"
        kind="Video"
        fileSizes={videoFileSizes}
        firebase={firebase}
        client={client}
        topMargin={categories.length > 0}
        canEdit={canEdit}
        updateFS={updateFS}
        theme={theme}
      />
    );
  }

  return (
    <div style={style} id="FileSizeCategoryContainer">
      {categories}
    </div>
  );
};

const FileSizeCategory = ({
  kind,
  fileSizes,
  firebase,
  client,
  topMargin,
  canEdit,
  updateFS,
  theme,
}) => {
  const [showDeleteSupplySize, setShowDeleteSupplySize] = useState(false);
  const [openReorderDialog, setOpenReorderDialog] = useState(false);
  const [reorderedFileSizes, setReorderedFileSizes] = useState(fileSizes || []);

  useEffect(() => {
    setReorderedFileSizes(fileSizes || []);
  }, [fileSizes]);

  const marginTop = 25;

  const style = {
    width: "95%",
    minWidth: 400,
    minHeight: "40px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginTop: topMargin ? `${marginTop}px` : "0px",
    right: -17,
    marginBottom: 50,
  };
  const title = {
    cursor: "pointer",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    fontSize: "1.4em",
    fontWeight: "bold",
  };

  let deleteSupplySizeTimeout = null;

  const handleNewSupplySizeClick = () => {
    if (!canEdit) return;
    client.fileSizes[`${kind.toLowerCase()}`].push({
      title: "",
      width: "",
      height: "",
      dpi: "",
      backgroundColour: "",
      fileSize: "",
      fileSizeUnit: "",
      fileFormat: "",
      colourSpace: "",
      noSupply: false,
    });
    updateFS(client.id, "fileSizes", client.fileSizes);
  };

  const handleDeleteSupplySizeClick = () => {
    if (!canEdit) return;
    if (deleteSupplySizeTimeout) {
      clearTimeout(deleteSupplySizeTimeout);
      deleteSupplySizeTimeout = null;
    } else {
      deleteSupplySizeTimeout = setTimeout(() => {
        setShowDeleteSupplySize(false);
        deleteSupplySizeTimeout = null;
      }, 15000);
    }
    setShowDeleteSupplySize(!showDeleteSupplySize);
  };

  const handleReorderClick = () => {
    setOpenReorderDialog(true);
  };

  const handleCloseReorderDialog = () => {
    setOpenReorderDialog(false);
  };

  const handleSaveReorderedFileSizes = () => {
    if (!canEdit) return;
    client.fileSizes[kind.toLowerCase()] = reorderedFileSizes;
    updateFS(client.id, "fileSizes", client.fileSizes);
    setOpenReorderDialog(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(reorderedFileSizes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setReorderedFileSizes(items);
  };

  return (
    <div style={style}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3 style={title}>{kind}</h3>
        </div>
        <div style={{ display: canEdit ? "block" : "none" }}>
          <IconButton onClick={handleReorderClick}>
            <SwapVertIcon />
          </IconButton>
          <IconButton onClick={handleNewSupplySizeClick}>
            <AddIcon />
          </IconButton>
          <IconButton onClick={handleDeleteSupplySizeClick}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
      <Dialog open={openReorderDialog} onClose={handleCloseReorderDialog}>
        <DialogTitle>Reorder {kind} File Sizes</DialogTitle>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="fileSizesList">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {reorderedFileSizes.map((fs, index) => (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          background: fs.noSupply
                            ? theme === "dark"
                              ? "rgba(240, 100, 100, 0.04)"
                              : "rgba(240, 100, 100, 0.08)"
                            : theme === "dark"
                            ? "#363636"
                            : "#f8f8f8",
                          borderBottom: "1px solid grey",
                          marginBottom: 1,
                        }}
                      >
                        <DragHandleIcon sx={{ marginRight: 2 }} />
                        <ListItemText primary={fs.title || "Untitled"} />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
        <DialogActions>
          <Button onClick={handleCloseReorderDialog}>Cancel</Button>
          <Button onClick={handleSaveReorderedFileSizes}>Save</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2} justifyContent="center">
        {fileSizes &&
          fileSizes.map((fs, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={`FileSizeContainer-${index}`}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <FileSizeContainer
                fs={fs}
                firebase={firebase}
                client={client}
                showDeleteSupplySize={showDeleteSupplySize}
                kind={kind}
                updateFS={updateFS}
                canEdit={canEdit}
                theme={theme}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

const FileSizeContainer = ({
  fs,
  firebase,
  client,
  showDeleteSupplySize,
  kind,
  updateFS,
  canEdit,
  theme,
}) => {
  const fileFormats = ["psd", "jpg", "png", "webp", "tiff", "mp4", "webm"];
  const colourSpaces = ["sRGB", "Adobe 1998", "CMYK"];
  const fileSizeUnits = ["KB", "MB"];

  const fileUploadRef = useRef(null);

  const [title, setTitle] = useState(fs.title);
  const [width, setWidth] = useState(fs.width);
  const [height, setHeight] = useState(fs.height);
  const [dpi, setDpi] = useState(fs.dpi);
  const [fileFormat, setFileFormat] = useState(fs.fileFormat);
  const [removeITSBackground, setRemoveITSBackground] = useState(
    fs.removeITSBackground
  );
  const [backgroundColour, setBackgroundColour] = useState(fs.backgroundColour);
  const [colourSpace, setColourSpace] = useState(fs.colourSpace);
  const [fileSize, setFileSize] = useState(fs.fileSize);
  const [fileSizeUnit, setFileSizeUnit] = useState(fs.fileSizeUnit);
  const [noSupply, setNoSupply] = useState(fs.noSupply || false);

  const [fileUploading, setFileUploading] = useState(false);

  useEffect(() => {
    setTitle(fs.title);
    setWidth(fs.width);
    setHeight(fs.height);
    setDpi(fs.dpi);
    setFileFormat(fs.fileFormat);
    setBackgroundColour(fs.backgroundColour);
    setRemoveITSBackground(fs.removeITSBackground);
    setColourSpace(fs.colourSpace);
    setFileSize(fs.fileSize);
    setFileSizeUnit(fs.fileSizeUnit);
    setNoSupply(fs.noSupply || false);
  }, [fs]);

  const handleTitleChange = (e) => {
    if (!canEdit) return;
    setTitle(e.target.value);
    fs.title = e.target.value;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleWidthChange = (e) => {
    if (!canEdit) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setWidth(val);
    fs.width = val;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleHeightChange = (e) => {
    if (!canEdit) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setHeight(val);
    fs.height = val;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleDpiChange = (e) => {
    if (!canEdit) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setDpi(val);
    fs.dpi = val;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleFileFormatChange = (e, newValue) => {
    if (!canEdit) return;
    if (!newValue) {
      newValue = "";
    }
    setFileFormat(newValue);
    fs.fileFormat = newValue;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleRemoveITSBackgroundChange = (e) => {
    if (!canEdit) return;
    setRemoveITSBackground(e.target.checked);
    fs.removeITSBackground = e.target.checked;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleBackgroundColourChange = (e) => {
    if (!canEdit) return;
    setBackgroundColour(e.target.value);
    fs.backgroundColour = e.target.value;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleBackgroundColourChangeFileUpload = (url) => {
    if (!canEdit) return;
    setBackgroundColour(url);
    fs.backgroundColour = url;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleColourSpaceChange = (e, newValue) => {
    if (!canEdit) return;
    if (!newValue) {
      newValue = "";
    }
    setColourSpace(newValue);
    fs.colourSpace = newValue;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleFileSizeChange = (e) => {
    if (!canEdit) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setFileSize(val);
    fs.fileSize = val;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleFileSizeUnitChange = (e, newValue) => {
    if (!canEdit) return;
    if (!newValue) {
      newValue = "";
    }
    setFileSizeUnit(newValue);
    fs.fileSizeUnit = newValue;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleDeleteSupplySizeClick = () => {
    if (!canEdit) return;
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({
        [`fileSizes.${kind.toLowerCase()}`]:
          firebase.firestore.FieldValue.arrayRemove(fs),
      });
  };
  const handleNoSupplyChange = (e) => {
    if (!canEdit) return;
    setNoSupply(e.target.checked);
    fs.noSupply = e.target.checked;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  return (
    <Grid
      container
      spacing={1}
      style={{
        position: "relative",
        borderRadius: "8px",
        padding: "2px",
        paddingRight: "10px",
        maxWidth: 320,
        width: "100%",
        background: noSupply
          ? theme === "dark"
            ? "rgba(240, 100, 100, 0.04)"
            : "rgba(240, 100, 100, 0.08)"
          : "",
      }}
    >
      {showDeleteSupplySize && (
        <div
          style={{
            background: "#00000015",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
            position: "absolute",
            borderRadius: "10px",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
          }}
        >
          <IconButton onClick={handleDeleteSupplySizeClick}>
            <DeleteIcon
              style={{
                color: "#ff0033",
                width: 60,
                height: 60,
                filter: "drop-shadow(4px 4px 3px #00000060)",
              }}
            />
          </IconButton>
        </div>
      )}
      <Grid item xs={12}>
        <TextField
          InputProps={{ disableUnderline: true }}
          fullWidth
          variant="filled"
          label="Folder Name"
          value={title}
          onChange={handleTitleChange}
          inputProps={{ style: { fontSize: "0.8rem" } }}
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          InputProps={{ disableUnderline: true }}
          fullWidth
          variant="filled"
          label="Width"
          value={width}
          onChange={handleWidthChange}
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          InputProps={{ disableUnderline: true }}
          fullWidth
          variant="filled"
          label="Height"
          value={height}
          onChange={handleHeightChange}
          disabled={!canEdit}
        />
      </Grid>
      {kind !== "Video" && (
        <Grid item xs={6}>
          <TextField
            InputProps={{ disableUnderline: true }}
            fullWidth
            variant="filled"
            label="DPI"
            value={dpi}
            onChange={handleDpiChange}
            disabled={!canEdit}
          />
        </Grid>
      )}
      <Grid item xs={kind !== "Video" ? 6 : 12}>
        <Autocomplete
          value={fileFormat}
          onChange={handleFileFormatChange}
          inputValue={fileFormat}
          onInputChange={handleFileFormatChange}
          options={fileFormats}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              variant="filled"
              label="Format"
              disabled={!canEdit}
            />
          )}
        />
      </Grid>
      {kind !== "Video" && (
        <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={removeITSBackground}
                  onChange={handleRemoveITSBackgroundChange}
                  disabled={!canEdit}
                />
              }
              label={
                <span style={{ fontSize: 13 }}>Remove ITS Background</span>
              }
            />
          </FormGroup>
        </Grid>
      )}
      {kind !== "Video" && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="filled"
            label="Background"
            value={
              typeof backgroundColour === "object"
                ? backgroundColour.fileName
                : backgroundColour
            }
            onChange={handleBackgroundColourChange}
            disabled={!canEdit}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <>
                  {!fileUploading ? (
                    <IconButton
                      onClick={async () => {
                        if (!canEdit) return;
                        if (fileUploading) return;
                        fileUploadRef.current.value = null;
                        fileUploadRef.current.click();
                      }}
                    >
                      <FileUploadIcon />
                    </IconButton>
                  ) : (
                    <IconButton disabled>
                      <CircularProgress size={24} />
                    </IconButton>
                  )}
                  {typeof backgroundColour === "object" && (
                    <IconButton
                      onClick={() => {
                        saveAs(backgroundColour.url, backgroundColour.fileName);
                      }}
                    >
                      <FileDownloadIcon />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        </Grid>
      )}
      {kind !== "Video" && (
        <Grid item xs={12}>
          <Autocomplete
            value={colourSpace}
            onChange={handleColourSpaceChange}
            inputValue={colourSpace}
            onInputChange={handleColourSpaceChange}
            options={colourSpaces}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                variant="filled"
                label="Colour Space"
                disabled={!canEdit}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="filled"
          InputProps={{ disableUnderline: true }}
          label="Compress"
          value={fileSize}
          onChange={handleFileSizeChange}
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          value={fileSizeUnit}
          onChange={handleFileSizeUnitChange}
          inputValue={fileSizeUnit}
          onInputChange={handleFileSizeUnitChange}
          options={fileSizeUnits}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              variant="filled"
              label="Unit"
              disabled={!canEdit}
            />
          )}
        />
      </Grid>
      {/* No Supply Checkbox */}
      <Grid item xs={12}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={noSupply}
                onChange={handleNoSupplyChange}
                disabled={!canEdit}
              />
            }
            label="No Supply"
          />
        </FormGroup>
      </Grid>
      <input
        ref={fileUploadRef}
        type="file"
        style={{ display: "none" }}
        accept="image/png, image/jpeg"
        onChange={async (e) => {
          setFileUploading(true);
          try {
            const file = e.target.files[0];
            const storageRef = firebase
              .storage()
              .ref()
              .child(`/Client Background Plates/${client.brand}/${file.name}`);
            const snapshot = await storageRef.put(file);
            const url = await snapshot.ref.getDownloadURL();
            handleBackgroundColourChangeFileUpload({
              fileName: file.name,
              url: url,
            });
            setFileUploading(false);
          } catch (e) {
            console.log(e);
            setFileUploading(false);
          }
        }}
      />
    </Grid>
  );
};

export default FileSizes;
