import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

const Invoicing = ({ client, firebase, updateFS, canEdit, theme }) => {
  const titleStyle = {
    marginLeft: 10,
    marginTop: 10,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "bold",
    fontSize: "1.1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const textFieldStyle = {
    margin: "10px",
    flex: 1,
  };

  const [data, setData] = useState({
    fullDayRate: client?.fullDayRate || {
      images: "",
      overtimeExtraFiles: "",
      looks: "",
      angles: "",
      lunch: "",
    },
    halfDayRate: client?.halfDayRate || {
      images: "",
      overtimeExtraFiles: "",
      looks: "",
      angles: "",
    },
    contentFullDayRate: client?.contentFullDayRate || {
      images: "",
      overtimeExtraFiles: "",
      looks: "",
      angles: "",
      lunch: "",
    },
    contentHalfDayRate: client?.contentHalfDayRate || {
      images: "",
      overtimeExtraFiles: "",
      looks: "",
      angles: "",
    },
  });

  useEffect(() => {
    setData({
      fullDayRate: client?.fullDayRate || {
        images: "",
        overtimeExtraFiles: "",
        looks: "",
        angles: "",
        lunch: "",
      },
      halfDayRate: client?.halfDayRate || {
        images: "",
        overtimeExtraFiles: "",
        looks: "",
        angles: "",
      },
      contentFullDayRate: client?.contentFullDayRate || {
        images: "",
        overtimeExtraFiles: "",
        looks: "",
        angles: "",
        lunch: "",
      },
      contentHalfDayRate: client?.contentHalfDayRate || {
        images: "",
        overtimeExtraFiles: "",
        looks: "",
        angles: "",
      },
    });
  }, [client]);

  const handleNestedChange = (category, field, value) => {
    if (!canEdit) return;
    if (field !== "lunch") {
      value = value.replace(/\D/g, "");
    }
    setData((prev) => ({
      ...prev,
      [category]: { ...prev[category], [field]: value },
    }));
    updateFS(client?.id, category, { ...data[category], [field]: value });
  };

  const renderInputs = (category, hasLunch = false) => {
    return (
      <div>
        <div style={{ display: "flex", gap: "10px" }}>
          <TextField
            InputProps={{ disableUnderline: true }}
            label="Images"
            value={data[category].images || ""}
            onChange={(e) =>
              handleNestedChange(category, "images", e.target.value)
            }
            variant="filled"
            size="small"
            disabled={!canEdit}
            style={textFieldStyle}
            error={!data[category].images}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            label="Overtime Extra Images (Per Hour)"
            value={data[category].overtimeExtraFiles || ""}
            onChange={(e) =>
              handleNestedChange(category, "overtimeExtraFiles", e.target.value)
            }
            variant="filled"
            size="small"
            disabled={!canEdit}
            style={textFieldStyle}
          />
        </div>
        <div style={{ display: "flex", gap: "10px", flex: 1 }}>
          <TextField
            InputProps={{ disableUnderline: true }}
            label="Looks"
            value={data[category].looks || ""}
            onChange={(e) =>
              handleNestedChange(category, "looks", e.target.value)
            }
            variant="filled"
            size="small"
            disabled={!canEdit}
            style={textFieldStyle}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            label="Angles"
            value={data[category].angles || ""}
            onChange={(e) =>
              handleNestedChange(category, "angles", e.target.value)
            }
            variant="filled"
            size="small"
            disabled={!canEdit}
            style={textFieldStyle}
          />
          {hasLunch && (
            <TextField
              InputProps={{ disableUnderline: true }}
              label="Lunch"
              value={data[category].lunch || ""}
              onChange={(e) =>
                handleNestedChange(category, "lunch", e.target.value)
              }
              variant="filled"
              size="small"
              disabled={!canEdit}
              style={textFieldStyle}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: 820,
        margin: "0 auto",
      }}
    >
      <div
        style={{
          overflow: "auto",
          maxHeight: "calc(100vh - 220px)",
          padding: "10px",
        }}
      >
        <h3 style={titleStyle}>Full Day Ecomm</h3>
        {renderInputs("fullDayRate", true)}
        <h3 style={titleStyle}>Half Day Ecomm</h3>
        {renderInputs("halfDayRate")}

        <h3 style={titleStyle}>Full Day Content</h3>
        {renderInputs("contentFullDayRate", true)}
        <h3 style={titleStyle}>Half Day Content</h3>
        {renderInputs("contentHalfDayRate")}
      </div>
    </div>
  );
};

export default Invoicing;
