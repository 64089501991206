import React, { useState, useEffect } from "react";

import { Snackbar, Alert } from "@mui/material";

import { useAtom } from "jotai";
import { isAdminAtom } from "../../../-Atoms";

import FileSizes from "../FileSizes";

const JobInfo = ({ t, jobData, clientData, firebase, isDarkMode }) => {
  const [jobTypeFull, setJobTypeFull] = useState(jobData?.jobTypeFull);

  useEffect(() => {
    setJobTypeFull(jobData.jobTypeFull);
  }, [jobData]);

  const [copyToClipboardSnackbarOpen, setCopyToClipboardSnackbarOpen] =
    useState(false);

  const updateFSCDB = debounce(async (id, field, value) => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .set({ [field]: value }, { merge: true });
  }, 1200);

  const [isAdmin] = useAtom(isAdminAtom);

  return (
    <div>
      <div style={{ marginLeft: 10, marginRight: 10 }}>
        <FileSizes
          client={clientData}
          firebase={firebase}
          updateFS={updateFSCDB}
          canEdit={isAdmin}
          theme={isDarkMode ? "dark" : "light"}
          filter={
            jobTypeFull?.includes("-L") || jobTypeFull?.includes("-R")
              ? "life"
              : jobTypeFull?.includes("-P") || jobTypeFull?.includes("-R")
              ? "product"
              : jobTypeFull?.includes("-V") || jobTypeFull?.includes("-E")
              ? "video"
              : false
          }
        />
      </div>
      <Snackbar
        open={copyToClipboardSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setCopyToClipboardSnackbarOpen(false)}
      >
        <Alert elevation={6} variant="filled" severity="success">
          Settings copied to clipboard
        </Alert>
      </Snackbar>
      {/* {jobTypeFull?.includes("-L") || jobTypeFull?.includes("-R") ? (
        lifeFileSizes ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                padding: "4px",
                color: isDarkMode ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              Life
            </span>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(lifeFileSizes));
                setCopyToClipboardSnackbarOpen(true);
              }}
            >
              Copy Settings
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {lifeFileSizes.map((fs, index) => (
                <FileSizeCategory
                  key={`FS-CAT-LIFE-${index}`}
                  fs={fs}
                  jobTypeFull={jobTypeFull}
                  firebase={firebase}
                  clientData={clientData}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>No life file sizes in database</div>
        )
      ) : null}

      {jobTypeFull?.includes("-P") || jobTypeFull?.includes("-R") ? (
        productFileSizes ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "22px",
                padding: "4px",
                color: isDarkMode ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              Product
            </span>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(productFileSizes));
                setCopyToClipboardSnackbarOpen(true);
              }}
            >
              Copy Settings
            </Button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {productFileSizes.map((fs, index) => (
                <FileSizeCategory
                  key={`FS-CAT-LIFE-${index}`}
                  fs={fs}
                  jobTypeFull={jobTypeFull}
                  firebase={firebase}
                  clientData={clientData}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>No product file sizes in database</div>
        )
      ) : null}

      {jobTypeFull?.includes("-V") || jobTypeFull?.includes("-E") ? (
        videoFileSizes ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: "22px", padding: "4px" }}>Video</span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {videoFileSizes.map((fs, index) => (
                <FileSizeCategory
                  key={`FS-CAT-LIFE-${index}`}
                  fs={fs}
                  jobTypeFull={jobTypeFull}
                  firebase={firebase}
                  clientData={clientData}
                />
              ))}
            </div>
          </div>
        ) : (
          <div>No video file sizes in database</div>
        )
      ) : null}
      <div
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}
      >
        {otherFileSize1 && !jobTypeFull?.includes("-V") ? (
          <div>
            <Tooltip title="Other File Size 1" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Other File Size 1"
                value={otherFileSize1}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                style={{ margin: "10px" }}
                size="small"
              />
            </Tooltip>
          </div>
        ) : null}
        {otherFileSize2 && !jobTypeFull?.includes("-V") ? (
          <div>
            <Tooltip title="Other File Size 2" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Other File Size 2"
                value={otherFileSize2}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                style={{ margin: "10px" }}
                size="small"
              />
            </Tooltip>
          </div>
        ) : null}
        {otherFileSize3 && !jobTypeFull?.includes("-V") ? (
          <div>
            <Tooltip title="Other File Size 3" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Other File Size 3"
                value={otherFileSize3}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                style={{ margin: "10px" }}
                size="small"
              />
            </Tooltip>
          </div>
        ) : null}
        {otherFileSize4 && !jobTypeFull?.includes("-V") ? (
          <div>
            <Tooltip title="Other File Size 4" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Other File Size 4"
                value={otherFileSize4}
                InputLabelProps={{ style: { fontSize: "15px" } }}
                style={{ margin: "10px" }}
                size="small"
              />
            </Tooltip>
          </div>
        ) : null}
      </div> */}
    </div>
  );
};

export default JobInfo;

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
