import React from "react";
import TextField from "@mui/material/TextField";

const SpecialRequirementsContainer = ({
  value,
  setValue,
  field,
  label,
  updateFSNow,
  firebase,
  jobData,
  marginTop,
  canEdit,
  event,
}) => {
  return (
    <TextField
      label={label}
      variant="filled"
      InputProps={{ disableUnderline: true }}
      slotProps={{
        htmlInput: { spellCheck: true, lang: "en" },
      }}
      multiline
      minRows={5}
      maxRows={5}
      value={value || ""}
      style={{
        marginTop: marginTop,
        width: "100%",
        height: 100,
      }}
      onChange={(e) => {
        if (!canEdit) return;
        setValue(e.target.value);
        updateFSNow(
          firebase,
          jobData.id || event.extendedProps.id,
          field,
          e.target.value
        );
      }}
    />
  );
};

export default SpecialRequirementsContainer;
