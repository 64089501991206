const fetch = require("isomorphic-fetch");
const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  fetch: fetch,
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

async function getDropboxFileRequest(path, title) {
  await dbx
    .filesCreateFolderV2({
      path: path,
      autorename: false,
    })
    .catch((error) => {
      if (error.error.error_summary.includes("path/conflict/folder")) {
        // console.log(
        //   "technically im an error, but im an expected error so its all g bro"
        // );
        // console.log(
        //   "all im saying is, there was already a folder with that name, all good"
        // );
      } else console.error(JSON.stringify(error));
    });
  let existingRequestURL;
  const existingRequests = await dbx.fileRequestsListV2().catch((error) => {
    // console.log(error);
    return { error: true, errorText: "Could not connect to Dropbox..." };
  });
  existingRequests.result.file_requests.forEach((req) => {
    if (req.is_open) {
      if (req.title === title) {
        existingRequestURL = req.url;
      }
    }
  });
  if (existingRequestURL) return existingRequestURL;
  const res = await dbx
    .fileRequestsCreate({
      title: title,
      destination: path,
      open: true,
    })
    .catch(function (error) {
      console.error(JSON.stringify(error));
      return { error: true, errorText: error.error.error_summary };
    });
  // console.log(res);
  return res.result.url;
}

async function getDropboxLink(path, setShowUploadScreen = () => {}) {
  const existingLinkRes = await dbx
    .sharingListSharedLinks({
      path: path,
      direct_only: true,
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      // console.log(error);
      return {
        error: true,
        errorText: error.error.error_summary || error,
        result: { links: [] },
      };
    });
  if (!existingLinkRes) {
    return { error: true, errorText: "Could not connect to Dropbox..." };
  }

  if (existingLinkRes.error) {
    if (existingLinkRes.errorText.includes("path/not_found")) {
      // console.log("path not found");

      const createFolderRes = await dbx
        .filesCreateFolderV2({
          path: path,
          autorename: false,
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          return { error: true, errorText: error.error.error_summary };
        });
      if (createFolderRes.error) {
        return createFolderRes;
      }
    } else return existingLinkRes;
  }

  if (existingLinkRes.result.links.length === 0) {
    const createLinkRes = await dbx
      .sharingCreateSharedLinkWithSettings({
        path: path,
        settings: {
          requested_visibility: "public",
          audience: "public",
          access: "viewer",
        },
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        return { error: true, errorText: error.error.error_summary };
      });
    return createLinkRes.result.url;
  } else {
    return existingLinkRes.result.links[0].url;
  }
}
const getDropboxUrlWithRetry = async (basePath, maxRetries = 10) => {
  const dropboxUrlRegex =
    /^https:\/\/(www\.dropbox\.com|dl\.dropboxusercontent\.com)\/.+/i;

  let attempts = 0;
  let storyboardsUrl = null;

  while (attempts < maxRetries) {
    try {
      storyboardsUrl = await getDropboxLink(basePath, noop);

      if (dropboxUrlRegex.test(storyboardsUrl)) {
        // console.log(`Successfully got Dropbox URL on attempt ${attempts + 1}`);
        return storyboardsUrl;
      }

      console.warn(
        `Invalid Dropbox URL on attempt ${attempts + 1}, retrying...`
      );
      attempts++;

      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error(`Error on attempt ${attempts + 1}:`, error);
      attempts++;

      if (attempts < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  }

  console.error(`Failed to get valid Dropbox URL after ${maxRetries} attempts`);
  return false;
};

const listFolderContents = async (path = "", cursor = null) => {
  try {
    let response;
    if (cursor) {
      response = await dbx.filesListFolderContinue({ cursor });
    } else {
      response = await dbx.filesListFolder({
        path,
        recursive: true,
        include_media_info: true,
        limit: 400,
      });
    }

    const result = response.result;
    const entries = result.entries.filter(
      (entry) =>
        entry &&
        entry[".tag"] === "file" &&
        entry.path_lower &&
        entry.path_lower.match(/\.(jpg|jpeg)$/i)
    );

    return {
      entries,
      hasMore: result.has_more || false,
      cursor: result.cursor || null,
    };
  } catch (error) {
    console.error("Error listing folder:", error);
    return {
      entries: [],
      hasMore: false,
      cursor: null,
    };
  }
};

const getThumbnails = async (files) => {
  if (!files || files.length === 0) return {};

  try {
    const entries = files.map((file) => ({
      path: file.path_lower,
      format: "jpeg",
      size: "w1024h768",
      mode: "strict",
      quality: "quality_80",
    }));

    const response = await dbx.filesGetThumbnailBatch({ entries });
    const result = response.result;

    return result.entries.reduce((acc, entry) => {
      if (
        entry &&
        entry[".tag"] === "success" &&
        entry.metadata &&
        entry.thumbnail
      ) {
        acc[entry.metadata.path_lower] = entry.thumbnail;
      }
      return acc;
    }, {});
  } catch (error) {
    console.error("Error getting thumbnails:", error);
    return {};
  }
};

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function getAllFiles(path, onUpdate = null) {
  try {
    let entries = [];
    let cursor = null;

    do {
      let listResult;
      while (true) {
        try {
          listResult = cursor
            ? await dbx.filesListFolderContinue({ cursor })
            : await dbx.filesListFolder({
                path,
                recursive: true,
                include_media_info: true,
              });
          break;
        } catch (error) {
          if (error.status === 429) {
            await sleep(error.headers["retry-after"] * 1000 || 60000);
            continue;
          }
          throw error;
        }
      }

      entries = [...entries, ...listResult.result.entries];
      cursor = listResult.result.has_more ? listResult.result.cursor : null;
    } while (cursor);

    const filteredEntries = entries.filter(
      (entry) =>
        entry &&
        entry[".tag"] === "file" &&
        entry.path_lower &&
        entry.path_lower.match(/\.(jpg|jpeg)$/i)
    );

    const files = filteredEntries.map((entry) => ({
      ...entry,
      url: null,
    }));

    if (onUpdate) onUpdate(files);

    for (let i = 0; i < files.length; i++) {
      const entry = files[i];
      if (entry[".tag"] === "file") {
        while (true) {
          try {
            const tempLink = await dbx.filesGetTemporaryLink({
              path: entry.path_display,
            });
            files[i] = { ...entry, url: tempLink.result.link };
            if (onUpdate) onUpdate(files);
            break;
          } catch (error) {
            if (error.status === 429) {
              await sleep(error.headers["retry-after"] * 1000 || 60000);
              continue;
            }
            console.error(
              `Error getting link for ${entry.path_display}:`,
              error
            );
            break;
          }
        }
      }
    }

    return files;
  } catch (error) {
    console.error("Error listing files:", error);
    throw error;
  }
}

async function batchCopyToClientSelects(files, basePath) {
  const now = new Date();
  const dateString = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} - ${String(now.getHours() % 12 || 12).padStart(2, '0')}.${String(now.getMinutes()).padStart(2, '0')} ${now.getHours() >= 12 ? 'pm' : 'am'}`;

  const destinationFolder = `${basePath}/Client Selects/${dateString}`;

  try {
    // console.log('Creating Client Selects folder...');
    await dbx.filesCreateFolderV2({
      path: `${basePath}/Client Selects`,
      autorename: false
    }).catch(error => {
      if (!error.error.error_summary.includes("path/conflict/folder")) {
        throw error;
      }
    });

    // console.log('Creating dated folder...');
    await dbx.filesCreateFolderV2({
      path: destinationFolder,
      autorename: false
    });

    const entries = files.map(file => ({
      from_path: file.path_display,
      to_path: `${destinationFolder}/${file.name}`
    }));

    // console.log('Starting batch copy with entries:', entries);
    const batchResult = await dbx.filesCopyBatchV2({ entries });
    // console.log('Initial batch copy result:', batchResult);
    
    const asyncJobId = batchResult.result.async_job_id;
    // console.log('Async job ID:', asyncJobId);

    // Poll for job completion
    let isComplete = false;
    while (!isComplete) {
      await sleep(2000);
      const jobResult = await dbx.filesCopyBatchCheckV2({ 
        async_job_id: asyncJobId 
      });
      // console.log('Job check result:', jobResult);

      if (jobResult.result['.tag'] === 'complete') {
        // console.log('Batch copy completed successfully');
        isComplete = true;
        return {
          success: true,
          folder: destinationFolder
        };
      } else if (jobResult.result['.tag'] === 'failed') {
        throw new Error(`Batch operation failed: ${JSON.stringify(jobResult.result.failed)}`);
      }
      // If still in progress, continue polling
    }

  } catch (error) {
    console.error("Detailed error in batch copy:", error);
    return {
      success: false,
      error: error.message || 'Unknown error occurred'
    };
  }
}

export {
  getDropboxFileRequest,
  getDropboxLink,
  getDropboxUrlWithRetry,
  listFolderContents,
  getThumbnails,
  getAllFiles,
  batchCopyToClientSelects,
};
function noop() {}
