import React, { useState } from "react";

import Button from "@mui/material/Button";
import MDEditor, { commands } from "@uiw/react-md-editor";

const MarkdownNotesEditorClient = ({
  label,
  clientValue,
  setClientValue,
  theme,
}) => {
  const [toggleEdit, settoggleEdit] = useState("preview");
  const [maxHeight, setMaxHeight] = useState(250);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        cursor: "text",
        marginBottom: 20,
        minHeight: 80,
        maxHeight: maxHeight,
        overflow: "hidden",
      }}
      onClick={(e) => {
        if (toggleEdit === "edit") return;
        if (toggleEdit === "preview") {
          settoggleEdit("edit");
          setMaxHeight(800);
        }
      }}
    >
      <fieldset
        data-color-mode={theme ? theme : "light"}
        style={{
          border: "1px solid #b8b9ba",
          borderRadius: 4,
          width: "88%",
          padding: 10,
          overflow: "auto",
        }}
      >
        <legend>
          <span
            style={{
              marginLeft: 4,
              marginRight: 4,
              fontWeight: 400,
              color:
                theme === "dark"
                  ? "rgba(255, 255, 255, 0.6)"
                  : "rgba(0, 0, 0, 0.6)",
              fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              fontSize: "0.9rem",
              letterSpacing: "0.00938em",
            }}
          >
            {label}
          </span>
        </legend>
        {toggleEdit === "edit" ? (
          <>
            <MDEditor
              onFocus={(e) => {
                e.target.selectionStart = e.target.value.length + 1;
                e.target.selectionEnd = e.target.value.length + 1;
              }}
              value={clientValue}
              onChange={setClientValue}
              autoFocus={true}
              preview={toggleEdit}
              commands={[
                commands.bold,
                commands.italic,
                commands.strikethrough,
                commands.hr,
                commands.title4,
                commands.divider,
                commands.link,
                commands.quote,
                commands.code,
                commands.codeBlock,
                commands.divider,
                commands.unorderedListCommand,
                commands.orderedListCommand,
              ]}
              extraCommands={[]}
              style={{
                marginLeft: 15,
              }}
              height={200}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                style={{ width: "80%", height: 24, margin: 5 }}
                onClick={() => {
                  settoggleEdit("preview");
                  setMaxHeight(200);
                }}
              >
                Save
              </Button>
            </div>
          </>
        ) : (
          <>
            <MDEditor.Markdown
              source={clientValue}
              style={{
                padding: 4,
                background: "transparent",
                fontSize: 14,
              }}
              height={200}
            />
          </>
        )}
      </fieldset>
    </div>
  );
};

export default MarkdownNotesEditorClient;
