import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import {
  CloudUpload as CloudUploadIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Share as ShareIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  ContentCopy as ContentCopyIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  ListItemText,
  Box,
  ButtonGroup,
  useMediaQuery,
  useTheme as useMuiTheme,
  CircularProgress,
  Typography,
  Modal,
  Tooltip,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useAtom } from "jotai";
import { themeAtom, isAdminAtom } from "../../-Atoms";
import { v4 as uuidv4 } from "uuid";
import ClientTabBreakdownItem from "../ClientTabBreakdownItem";
import FileSizes from "./FileSizes";
import Contacts from "./Contacts";

import "../../App.css";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

import Notes from "./Client Tab Components/Notes";
import Invoicing from "./Client Tab Components/Invoicing";
import Checklists from "./Client Tab Components/Checklists";
import Files from "./Client Tab Components/Files";
import TextField from "@mui/material/TextField";

const ClientTitle = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(3),
  textAlign: "center",
}));

const SpreadsheetSection = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
  padding: theme.spacing(3),
  backgroundColor: "rgba(255, 255, 255, 0)",
  borderRadius: theme.shape.borderRadius,
}));

const SpreadsheetTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  maxWidth: "800px",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

const textFieldStyle = {
  margin: "10px",
  flex: 1,
};

const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

const ClientTab = ({
  jobClientData,
  firebase,
  theme,
  soloClose,
  modalWidth,
  modalClientData,
  kanbanView,
}) => {
  const [clientData, setClientData] = useState(jobClientData);
  useEffect(() => {
    if (jobClientData?.id) {
      setClientData(jobClientData);
    }
  }, [jobClientData]);

  const muiTheme = useMuiTheme();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down("md"));
  const [themeA] = useAtom(themeAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [canEdit, setCanEdit] = useState(isAdmin);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

  const [logoUploadOpen, setLogoUploadOpen] = useState(false);
  const [logoFile, setLogoFile] = useState(null);
  const [logoUploading, setLogoUploading] = useState(false);

  const handleLogoUpload = async () => {
    if (!logoFile) return;

    setLogoUploading(true);

    try {
      const storageRef = firebase.storage().ref();
      const logoRef = storageRef.child(
        `client-logos/${clientData?.id}/${logoFile.name}`
      );

      await logoRef.put(logoFile);

      const logoUrl = await logoRef.getDownloadURL();

      updateFS(clientData?.id, "logo", logoUrl);

      setLogoUploadOpen(false);
      setLogoFile(null);
    } catch (error) {
      console.error("Error uploading logo:", error);
    } finally {
      setLogoUploading(false);
    }
  };

  const [PSF, setPSF] = useState("");
  const [paymentTerms, setPaymentTerms] = useState("");
  const [turnAround, setTurnAround] = useState("");

  useEffect(() => {
    setPSF(clientData?.PSF);
    setPaymentTerms(clientData?.paymentTerms);
    setTurnAround(clientData?.turnAround);
  }, [clientData]);

  useEffect(() => {
    setCanEdit(isAdmin);
  }, [isAdmin]);

  const [isDigitalBackground, setIsDigitalBackground] = useState(
    clientData?.isDigitalBackground || false
  );

  useEffect(() => {
    let unsub;
    if (modalClientData && modalClientData?.id) {
      unsub = firebase
        .firestore()
        .collection("ClientDatabase")
        .doc(modalClientData?.id)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setClientData(data);
        });
    }
    return () => {
      try {
        unsub();
      } catch (e) {}
    };
    // eslint-disable-next-line
  }, []);

  const updateFS = debounce(async (id, field, value) => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .set({ [field]: value }, { merge: true });
  }, 800);

  const titleStyle = {
    marginLeft: 10,
    marginTop: 10,
    color: themeA === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "bold",
    fontSize: "1.1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const [attachments, setAttachments] = useState([]);
  const [suppliedLinks, setSuppliedLinks] = useState([]);

  useEffect(() => {
    if (clientData?.attachments) {
      setAttachments([...clientData?.attachments]);
    } else {
      setAttachments([]);
    }

    if (clientData?.suppliedLinks) {
      const reverse = clientData?.suppliedLinks.reverse();
      setSuppliedLinks(reverse);
    }
  }, [clientData]);

  const [imageBreakdown, setImageBreakdown] = useState(
    clientData?.imageBreakdown || []
  );
  useEffect(() => {
    setImageBreakdown(clientData?.imageBreakdown || []);
  }, [clientData]);

  const addNewBreakdownItem = () => {
    const newitemObj = {
      id: uuidv4(),
      description: "",
      jobTypesFilter: [],
      expectedPerHour: "",
      costPerFile: "",
      existingItem: true,
    };
    imageBreakdown.push(newitemObj);
    setImageBreakdown([...imageBreakdown]);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(clientData?.id)
      .update({ imageBreakdown: imageBreakdown }, { merge: true })
      .catch((e) => console.log(e));
  };
  const [showRemoveBreakdownItem, setShowRemoveBreakdownItem] = useState(false);
  const toggleRemoveBreakdownItem = () => {
    setShowRemoveBreakdownItem(true);
    setTimeout(() => {
      setShowRemoveBreakdownItem(false);
    }, 6000);
  };

  const [openReorderDialog, setOpenReorderDialog] = useState(false);
  const [reorderedImageBreakdown, setReorderedImageBreakdown] = useState(
    imageBreakdown || []
  );

  useEffect(() => {
    setReorderedImageBreakdown(imageBreakdown || []);
  }, [imageBreakdown]);

  const handleReorderClick = () => {
    setOpenReorderDialog(true);
  };

  const handleCloseReorderDialog = () => {
    setOpenReorderDialog(false);
  };

  const handleSaveReorderedImageBreakdown = () => {
    if (!canEdit) return;
    setImageBreakdown(reorderedImageBreakdown);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(clientData?.id)
      .update({ imageBreakdown: reorderedImageBreakdown }, { merge: true })
      .catch((e) => console.log(e));
    setOpenReorderDialog(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(reorderedImageBreakdown);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setReorderedImageBreakdown(items);
  };

  const [spreadsheetURL, setSpreadsheetURL] = useState(
    clientData?.spreadsheetURL
  );
  const [shareSpreadsheetWithClientsText, setShareSpreadsheetWithClientsText] =
    useState("Share With Clients");
  const [
    shareSpreadsheetWithBookingsText,
    setShareSpreadsheetWithBookingsText,
  ] = useState("Share With Bookings Only");
  const [noClick, setNoClick] = useState(false);

  useEffect(() => {
    setSpreadsheetURL(clientData?.spreadsheetURL);
  }, [clientData]);

  const handleSpreadhseetChange = (e) => {
    if (!canEdit) return;
    setSpreadsheetURL(e.target.value);
    updateFS(clientData?.id, "spreadsheetURL", e.target.value);
  };

  const copyToClipboard = () => {
    if (spreadsheetURL) {
      navigator.clipboard.writeText(spreadsheetURL);
      setCopySnackbarOpen(true);
    }
  };

  return (
    <div
      style={{
        width: !kanbanView ? "100%" : modalWidth,
        padding: soloClose ? 20 : 0,
        overflowY: soloClose ? "auto" : "",
        height: soloClose ? "100vh" : "",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: theme === "dark" ? "#1a1a1a" : "#f5f5f5",
          pt: isMobile ? 2 : 2,
          width: "100%",
        }}
      >
        {clientData?.logo ? (
          <div
            style={{
              width: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              marginTop: 5,
              marginBottom: 5,
              cursor: "pointer",
            }}
            onClick={() => setLogoUploadOpen(true)}
          >
            <img
              src={clientData?.logo}
              alt={`${clientData?.brand} logo`}
              style={{
                maxWidth: "100%",
                height: "auto",
                maxHeight: 100,
              }}
            />
          </div>
        ) : null}

        <ClientTitle variant="h4">{clientData?.brand}</ClientTitle>

        <ButtonGroup size="small" sx={{ mb: 2 }}>
          <Button onClick={() => setLogoUploadOpen(true)}>
            {clientData?.logo ? "Change Logo" : "Add Logo"}
          </Button>
        </ButtonGroup>
      </Box>
      <SpreadsheetSection>
        {!spreadsheetURL ? (
          <Button
            variant="contained"
            color="primary"
            startIcon={noClick ? <CircularProgress size={20} /> : <AddIcon />}
            disabled={noClick || !canEdit}
            onClick={async () => {
              if (!canEdit) return;
              if (!noClick) {
                setNoClick(true);
                const res = await fetch(
                  "https://us-central1-its-connect-main.cloudfunctions.net/createClientSpreadsheet",
                  {
                    method: "POST",
                    headers: {
                      "content-type": "application/json",
                    },
                    body: JSON.stringify({
                      brand: clientData?.brand,
                      id: clientData?.id,
                    }),
                  }
                )
                  .then((res) => {
                    return res.json();
                  })
                  .catch(() => {
                    alert("an error occurred");
                  });
                setSpreadsheetURL(res.url);
                updateFS(clientData?.id, "spreadsheetURL", res.url);
                setNoClick(false);
              }
            }}
          >
            {noClick ? "Creating..." : "Create Client Spreadsheet"}
          </Button>
        ) : (
          <>
            <ButtonsContainer>
              <Button
                variant="contained"
                color="primary"
                startIcon={
                  noClick &&
                  shareSpreadsheetWithClientsText ===
                  <CircularProgress style={{ width: 28, height: 28 }} /> ? (
                    <CircularProgress size={20} />
                  ) : (
                    <PeopleIcon />
                  )
                }
                disabled={noClick || !canEdit}
                onClick={async () => {
                  if (!canEdit) return;
                  if (!noClick) {
                    setNoClick(true);
                    setShareSpreadsheetWithClientsText(
                      <CircularProgress style={{ width: 28, height: 28 }} />
                    );
                    const spreadsheetId =
                      spreadsheetURL.match(/\/d\/(.+)\//)[1];
                    const clientEmails = [];
                    clientData?.contacts.forEach((c) => {
                      if (c.email) {
                        clientEmails.push(c.email);
                      }
                    });
                    await fetch(
                      "https://us-central1-its-connect-main.cloudfunctions.net/shareSpreadsheetToClients",
                      {
                        method: "POST",
                        headers: {
                          "content-type": "application/json",
                        },
                        body: JSON.stringify({
                          emails: clientEmails,
                          id: spreadsheetId,
                        }),
                      }
                    )
                      .then((res) => {
                        return res.json();
                      })
                      .catch(() => {
                        alert("an error occurred");
                      });
                    setShareSpreadsheetWithClientsText("Share With Clients");
                    setNoClick(false);
                  }
                }}
              >
                Share With Clients
              </Button>

              <Button
                variant="contained"
                color="secondary"
                startIcon={
                  noClick &&
                  shareSpreadsheetWithBookingsText ===
                  <CircularProgress style={{ width: 28, height: 28 }} /> ? (
                    <CircularProgress size={20} />
                  ) : (
                    <PersonIcon />
                  )
                }
                disabled={noClick}
                onClick={async () => {
                  if (!noClick) {
                    setNoClick(true);
                    setShareSpreadsheetWithBookingsText(
                      <CircularProgress style={{ width: 28, height: 28 }} />
                    );
                    const spreadsheetId =
                      spreadsheetURL.match(/\/d\/(.+)\//)[1];
                    const clientEmails = ["bookings@imagesthatsell.com.au"];
                    await fetch(
                      "https://us-central1-its-connect-main.cloudfunctions.net/shareSpreadsheetToClients",
                      {
                        method: "POST",
                        headers: {
                          "content-type": "application/json",
                        },
                        body: JSON.stringify({
                          emails: clientEmails,
                          id: spreadsheetId,
                        }),
                      }
                    )
                      .then((res) => {
                        return res.json();
                      })
                      .catch(() => {
                        alert("an error occurred");
                      });
                    setShareSpreadsheetWithBookingsText(
                      "Share With Bookings Only"
                    );
                    setNoClick(false);
                  }
                }}
              >
                Share With Bookings Only
              </Button>
            </ButtonsContainer>

            <SpreadsheetTextField
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <Box sx={{ display: "flex" }}>
                    <Tooltip title="Copy to clipboard">
                      <IconButton
                        size="small"
                        onClick={copyToClipboard}
                        edge="end"
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Open spreadsheet">
                      <IconButton
                        size="small"
                        onClick={() => window.open(spreadsheetURL, "_blank")}
                        edge="end"
                      >
                        <OpenInNewIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
              }}
              variant="filled"
              label="Spreadsheet URL"
              onChange={handleSpreadhseetChange}
              value={spreadsheetURL}
              disabled={!canEdit}
            />
          </>
        )}
      </SpreadsheetSection>

      <Contacts client={clientData} firebase={firebase} />

      <div style={titleStyle}>
        <span>Image Breakdown</span>
        <div>
          <IconButton onClick={handleReorderClick}>
            <SwapVertIcon />
          </IconButton>
          <IconButton onClick={toggleRemoveBreakdownItem}>
            <RemoveIcon />
          </IconButton>
          <IconButton onClick={addNewBreakdownItem}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: -10,
          gap: 2,
          paddingTop: 10,
          paddingBottom: 30,
          width: "100%",
          maxWidth: 800,
        }}
      >
        {imageBreakdown &&
          imageBreakdown.map((bi, index) => (
            <ClientTabBreakdownItem
              key={bi.id}
              item={bi}
              items={imageBreakdown}
              setItems={setImageBreakdown}
              index={index}
              showRemoveBreakdownItem={showRemoveBreakdownItem}
              firebase={firebase}
              clientData={clientData}
              updateFS={updateFS}
            />
          ))}
      </div>

      <Dialog open={openReorderDialog} onClose={handleCloseReorderDialog}>
        <DialogTitle>Reorder Image Breakdown Items</DialogTitle>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="imageBreakdownList">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {reorderedImageBreakdown.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          background: themeA === "dark" ? "#363636" : "#f8f8f8",
                          borderBottom: "1px solid grey",
                          marginBottom: 1,
                        }}
                      >
                        <DragHandleIcon sx={{ marginRight: 2 }} />
                        <ListItemText
                          primary={item.description || "Untitled"}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
        <DialogActions>
          <Button onClick={handleCloseReorderDialog}>Cancel</Button>
          <Button onClick={handleSaveReorderedImageBreakdown}>Save</Button>
        </DialogActions>
      </Dialog>

      <div
        style={{
          width: "100%",
          maxWidth: 810,
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          InputProps={{ disableUnderline: true }}
          label="PSF"
          value={PSF}
          onChange={(e) => {
            setPSF(e.target.value);
            updateFS(clientData?.id, "PSF", e.target.value);
          }}
          variant="filled"
          size="small"
          disabled={!canEdit}
          style={textFieldStyle}
        />
        <TextField
          InputProps={{ disableUnderline: true }}
          label="Payment Terms"
          value={paymentTerms}
          onChange={(e) => {
            setPaymentTerms(e.target.value);
            updateFS(clientData?.id, "paymentTerms", e.target.value);
          }}
          variant="filled"
          size="small"
          disabled={!canEdit}
          style={textFieldStyle}
        />
        <TextField
          InputProps={{ disableUnderline: true }}
          label="Turn Around"
          value={turnAround}
          onChange={(e) => {
            setTurnAround(e.target.value);
            updateFS(clientData?.id, "turnAround", e.target.value);
          }}
          variant="filled"
          size="small"
          disabled={!canEdit}
          style={textFieldStyle}
        />
      </div>

      <Invoicing
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
        theme={theme}
      />

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FormGroup row={true} sx={{ gap: 5 }}>
          <FormControlLabel
            control={
              <Switch
                checked={isDigitalBackground}
                onChange={(e) => {
                  setIsDigitalBackground(e.target.checked);
                  firebase
                    .firestore()
                    .collection("ClientDatabase")
                    .doc(clientData?.id)
                    .update({
                      isDigitalBackground: e.target.checked,
                    });
                }}
              />
            }
            label={
              isDigitalBackground ? "Digital Background" : "Organic Background"
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={clientData?.noRetouching}
                onChange={(e) => {
                  firebase
                    .firestore()
                    .collection("ClientDatabase")
                    .doc(clientData?.id)
                    .update({ noRetouching: e.target.checked });
                }}
              />
            }
            label="No Retouching"
          />
        </FormGroup>
      </div>

      <FileSizes
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
        theme={theme}
      />
      <Notes
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
        theme={theme}
        modalWidth={modalWidth}
      />
      <Files
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        attachments={attachments}
        setAttachments={setAttachments}
        dbx={dbx}
        suppliedLinks={suppliedLinks}
        canEdit={canEdit}
        theme={theme}
      />
      <Checklists
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
      />

      <Modal
        open={logoUploadOpen}
        onClose={() => !logoUploading && setLogoUploadOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "85%" : 400,
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Upload Client Logo
          </Typography>

          <Box sx={{ mb: 3 }}>
            <Button
              component="label"
              variant="outlined"
              startIcon={<CloudUploadIcon />}
              sx={{ mb: 2 }}
              disabled={logoUploading}
            >
              Select Image
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={(e) => setLogoFile(e.target.files[0])}
              />
            </Button>

            {logoFile && (
              <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                Selected: {logoFile.name}
              </Typography>
            )}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button
              onClick={() => setLogoUploadOpen(false)}
              disabled={logoUploading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleLogoUpload}
              disabled={!logoFile || logoUploading}
              startIcon={logoUploading ? <CircularProgress size={16} /> : null}
            >
              {logoUploading ? "Uploading..." : "Upload"}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={copySnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setCopySnackbarOpen(false)}
        message="URL copied to clipboard"
      />
    </div>
  );
};

export default ClientTab;

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
