// src/VersionCheck.js
import React, { useEffect, useState } from "react";
import { Snackbar, Button, SnackbarContent } from "@mui/material";

const VersionCheck = ({ firebase }) => {
  const [releaseId, setReleaseId] = useState(null);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [error, setError] = useState("");
  const [, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (window.location.hostname === "localhost") {
      return;
    }

    const checkVersion = async () => {
      console.log("check");
      try {
        const getCurrentHostingRelease = firebase
          .functions()
          .httpsCallable("getCurrentHostingRelease");

        const version = await getCurrentHostingRelease().then(
          (res) => res.data
        );
        // const version = "934109731"; // Replace with actual version fetching
        if (version !== releaseId) {
          setNewVersionAvailable(true);
        }
      } catch (err) {
        setError("Error checking version: " + err.message);
      }
    };

    const initialFetch = async () => {
      console.log("init");
      try {
        const getCurrentHostingRelease = firebase
          .functions()
          .httpsCallable("getCurrentHostingRelease");

        const version = await getCurrentHostingRelease().then(
          (res) => res.data
        );

        setReleaseId(version);
      } catch (err) {
        setError("Error fetching version: " + err.message);
      }
    };

    initialFetch();

    // const intervalId = setInterval(checkVersion, 10 * 1000);
    const intervalId = setInterval(checkVersion, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [releaseId, firebase]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div>
      <Snackbar
        open={newVersionAvailable}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ marginTop: 3 }}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#4caf50",
            color: "white",
          }}
          message={
            <span>
              <span style={{ fontWeight: "bold" }}>Page out of date</span>
              <br />
              Please refresh to stay on the latest version.
            </span>
          }
          action={
            <Button
              variant="contained"
              color="inherit"
              size="small"
              sx={{ color: "white" }}
              onClick={handleRefresh}
            >
              Refresh
            </Button>
          }
        />
      </Snackbar>
    </div>
  );
};

export default VersionCheck;
